import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AuthService } from 'shared/auth/auth.service';
import {
    getAuthToken,
    getIsLoggedIn,
    getLoginResult,
    getRoles,
    setAuthToken,
    setImpersonation,
    setLoginResult as setLoginResultReducer,
} from 'state/auth/slice';
import { useAppSelector } from 'state/buildStore';
import { resetFacility } from 'state/facility/slice';
import { resetProperty } from 'state/property/slice';

const useAuth = () => {
    const dispatch = useDispatch();

    const authService = useMemo(() => new AuthService(), []);

    const login = useCallback(
        async (data) => {
            const result = await authService.login(data);
            dispatch(setLoginResultReducer(result));

            const session = await authService.isLoggedIn();

            if (session) {
                dispatch(
                    setAuthToken([
                        session.getAccessToken().getJwtToken(),
                        session.getIdToken().payload,
                        session.getIdToken().getJwtToken(),
                    ]),
                );
            }
        },
        [authService, dispatch],
    );

    const logout = useCallback(async () => {
        await authService.logout();
        dispatch(setAuthToken(null));
        dispatch(setImpersonation(null));
    }, [authService, dispatch]);

    const setLoginResult = useCallback(
        (str) => dispatch(setLoginResultReducer(str)),
        [dispatch],
    );

    const roles = useSelector(getRoles);
    const authToken = useSelector(getAuthToken);
    const isLoggedIn = useSelector(getIsLoggedIn);
    const loginResult = useSelector(getLoginResult);
    const impersonation = useAppSelector((state) => state.auth.impersonation);

    useEffect(() => {
        if (isLoggedIn === null) {
            authService.isLoggedIn().then((session) => {
                if (session) {
                    dispatch(
                        setAuthToken([
                            session.getAccessToken().getJwtToken(),
                            session.getIdToken().payload,
                            session.getIdToken().getJwtToken(),
                        ]),
                    );
                } else {
                    dispatch(setAuthToken(null));
                    dispatch(resetFacility());
                    dispatch(resetProperty());
                }
            });
        }
    }, [authService, dispatch, isLoggedIn]);

    return {
        actions: {
            login,
            logout,
            setLoginResult,
        },
        roles,
        authService,
        authToken,
        isLoggedIn,
        loginResult,
        impersonation,
    };
};

export default useAuth;
