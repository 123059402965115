/* eslint-disable max-len */
import { SVGProps } from 'react';

const LockAlt = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={16}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            opacity={0.2}
            d='M13 5.5H3a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5Z'
            fill='#F4F1ED'
        />
        <path
            d='M13 5.5H3a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5ZM5.75 5.5V3.25a2.25 2.25 0 1 1 4.5 0V5.5'
            stroke='#F4F1ED'
            strokeWidth={0.938}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M8 10.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z'
            fill='#F4F1ED'
        />
    </svg>
);

export default LockAlt;
