/* eslint-disable max-len */
import { SVGProps } from 'react';

const LogoMark = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={120}
        height={120}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <g opacity={0.4} fill='#61676B'>
            <path d='M118.45 46.315 19.397 15.69A60.082 60.082 0 0 0 8.492 28.958l110.596 20.593a100.64 100.64 0 0 0-.638-3.236ZM21.593 106.546l97.181-34.528c.177-.873.402-1.726.539-2.619 0-.225 0-.431.069-.657L10.1 93.748a60.377 60.377 0 0 0 11.493 12.798ZM50.287 119.637l65.447-37.097a71.105 71.105 0 0 0 1.706-4.903l-85.216 36.018a59.682 59.682 0 0 0 18.063 5.982ZM.294 67.281a59.886 59.886 0 0 0 4.237 16.073l115.381-19.309c.059-.922.068-1.834.088-2.746L.294 67.281ZM114.989 36.009 47.326 1.216a59.868 59.868 0 0 0-17.75 6.864l87.276 32.744c-.529-1.599-1.118-3.236-1.863-4.815ZM111.429 91.248 67.105 119.98a60.155 60.155 0 0 0 44.324-28.732ZM119.715 54.16 3.452 39.52A61.516 61.516 0 0 0 .51 50.884C.255 52.532.118 54.18 0 55.788l119.98 1.089c-.049-.903-.176-1.805-.265-2.717ZM69.174.54C67.438.274 65.722.117 63.996 0l46.374 27.458A59.9 59.9 0 0 0 69.174.539Z' />
        </g>
    </svg>
);

export default LogoMark;
