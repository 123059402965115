/* eslint-disable max-len */
import React from 'react';

const Card: React.FC = (props) => (
    <svg viewBox='0 0 14 10' fill='none' {...props}>
        <path
            d='M13.6665 2.34754V1.12438C13.6665 0.688092 13.333 0.333328 12.9214 0.333328H1.07873C0.666642 0.333095 0.333374 0.687859 0.333374 1.12438V2.34754H13.6665Z'
            fill='currentColor'
        />
        <path
            d='M0.333252 3.7719V8.87532C0.333252 9.31161 0.666519 9.66661 1.0786 9.66661H12.9215C13.3331 9.66661 13.6666 9.31185 13.6666 8.87555V3.7719H0.333252Z'
            fill='currentColor'
        />
    </svg>
);

export default Card;
