import { PayloadAction } from '@reduxjs/toolkit';

import { SliceState } from './slice';

const getPendingAmount = (transactions: any = []) =>
    transactions.reduce((acc: any, el: { status: string; amount: any }) => {
        if (el?.status === 'Pending') {
            return acc + (el?.amount || 0);
        }

        return acc;
    }, 0);

export const setFacilityReducer = (
    state: SliceState,
    action: PayloadAction<SliceState>,
) => {
    const transactions = state.statements || action.payload?.statements;
    const pendingAmount = getPendingAmount(transactions);

    return {
        ...action.payload,
        statements: transactions,
        pendingAmount,
    };
};

export const setRepaymentAmountReducer = (
    state: SliceState,
    action: PayloadAction<{ amount: number }>,
) => {
    return {
        ...state,
        repaymentAmount: action.payload?.amount,
    };
};
