/* eslint-disable max-len */
import React from 'react';

const Home: React.FC = (props) => (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
        <path
            d='M23.7949 24.0002H17.8975C17.7842 24.0002 17.6924 23.9084 17.6924 23.7951V5.33355C17.6924 5.22026 17.7842 5.12842 17.8975 5.12842H23.7949C23.9082 5.12842 24.0001 5.22026 24.0001 5.33355V23.7951C24.0001 23.9084 23.9082 24.0002 23.7949 24.0002ZM18.1026 23.59H23.5898V5.53867H18.1026V23.59Z'
            fill='currentColor'
        />
        <path
            d='M17.8975 24.0002H12.0001C11.8868 24.0002 11.7949 23.9083 11.7949 23.795V13.5386C11.7949 13.4253 11.8868 13.3335 12.0001 13.3335H17.8975C18.0108 13.3335 18.1026 13.4253 18.1026 13.5386V23.795C18.1026 23.9083 18.0108 24.0002 17.8975 24.0002ZM12.2052 23.5899H17.6924V13.7438H12.2052V23.5899Z'
            fill='currentColor'
        />
        <path
            d='M12 24H6.10259C5.98931 24 5.89746 23.9082 5.89746 23.7949V0.205128C5.89746 0.0918461 5.98931 0 6.10259 0H12C12.1133 0 12.2052 0.0918461 12.2052 0.205128V23.7949C12.2052 23.9082 12.1133 24 12 24ZM6.30772 23.5897H11.7949V0.410256H6.30772V23.5897Z'
            fill='currentColor'
        />
        <path
            d='M6.10256 24H0.205128C0.0918461 24 0 23.9081 0 23.7948V8.41021C0 8.29692 0.0918461 8.20508 0.205128 8.20508H6.10256C6.21585 8.20508 6.30769 8.29692 6.30769 8.41021V23.7948C6.30769 23.9081 6.21585 24 6.10256 24ZM0.410256 23.5897H5.89744V8.61533H0.410256V23.5897Z'
            fill='currentColor'
        />
    </svg>
);

export default Home;
