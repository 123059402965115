import styled from '@emotion/styled';
import { grey100, grey600, grey900, grey950, white } from 'theme/colors';
import * as media from 'theme/custom-media';

export const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 10.9rem 1.5rem 0;
    background-color: ${grey100};
    outline: none;
    color: ${grey900};
    overflow: auto;

    ${media.gteMediumMedia} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: 39.6rem;
        padding: 4.6rem 2rem 2rem;
        border-radius: 0.6rem;
        background-color: ${white};
    }
`;

export const Heading = styled.p`
    font-size: 2.8rem;
    line-height: 3.36rem;
    margin-bottom: 1.8rem;
    color: ${grey950};
    text-align: center;
`;

export const BodyContent = styled.div`
    padding: 1.6rem;
    background-color: ${white};
`;

export const Title = styled.p`
    font-size: 1.8rem;
    line-height: 2.52rem;
    margin-bottom: 1rem;
    color: ${grey950};
`;

export const ErrorTitle = styled(Title)`
    text-align: center;
`;

export const InfoRow = styled.div`
    display: flex;

    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }
`;

export const InfoBox = styled.div`
    flex: 1;
    padding: 1rem;

    &:not(:last-of-type) {
        margin-right: 1rem;
    }

    &:only-of-type {
        & p:last-of-type {
            line-height: 2.55rem;
        }
    }
`;

export const InfoTitle = styled.p`
    font-size: 1.4rem;
    line-height: 1.82rem;
    margin-bottom: 0.4rem;
`;

export const InfoData = styled.p`
    font-size: 1.7rem;
    line-height: 2.04rem;
`;

export const ArrowButton = styled.button`
    position: absolute;
    top: 6.133rem;
    left: 1.933rem;
    width: 2.533rem;
    background-color: transparent;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: ${grey600};
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    width: 1.026rem;
    background-color: transparent;
    padding: 0;
    margin: 2.487rem 2.487rem 0 0;
    border: 0;
    cursor: pointer;
    color: ${grey600};
`;
