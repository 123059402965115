import { FC } from 'react';

import { Container } from './Dashboard.styles';
// import PropertyChart from 'components/property-chart/PropertyChart';
import Main from './components/main/Main';
import ProductDetails from './components/product-details/ProductDetails';
import Statements from './components/statements/Statements';

const Dashboard: FC = () => {
    return (
        <Container>
            <Main />
            <Statements />
            <div>
                <ProductDetails />
                {/* <PropertyChart /> */}
            </div>
        </Container>
    );
};

export default Dashboard;
