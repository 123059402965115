import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Close } from '../icons';

import { Banner, CloseButton, Text } from './Disclaimer.styles';

const Disclaimer: FC = () => {
    const { t } = useTranslation('disclaimer');
    const showBanner = sessionStorage.getItem('show_disclaimer') !== 'false';
    const [show, setShow] = useState(showBanner);

    const handleOnClose = (): void => {
        setShow(false);
        sessionStorage.setItem('show_disclaimer', 'false');
    };

    if (!show) {
        return null;
    }

    return (
        <Banner>
            <Text>{t('text')}</Text>
            <CloseButton onClick={handleOnClose} data-testid='close-disclaimer'>
                <Close />
            </CloseButton>
        </Banner>
    );
};

export default Disclaimer;
