import { FC } from 'react';

import NextInstallment from 'components/next-installment/NextInstallment';
import useFacility from 'helpers/useFacility';
import useViewport from 'helpers/useViewport';
import { useTranslation } from 'react-i18next';
import { GBPFormatter } from 'utils/formatNumber';

import {
    Amount,
    Balance,
    BalanceBar,
    PaidBar,
    TextBox,
    TextContainer,
    Title,
} from './RepaymentBalance.styles';

const RepaymentBalance: FC = () => {
    const { t } = useTranslation('repaymentBalance');
    const { usedFund, creditLimit, owePercentage } = useFacility();
    const { gteMedium: isDesktop } = useViewport();

    return (
        <Balance>
            <TextContainer>
                <TextBox>
                    <Title>{t('outstanding')}</Title>
                    <Amount>{GBPFormatter(usedFund)}</Amount>
                </TextBox>
                <TextBox>
                    <Title>{t('credit-limit')}</Title>
                    <Amount>{GBPFormatter(creditLimit)}</Amount>
                </TextBox>
            </TextContainer>
            <BalanceBar>
                <PaidBar style={{ width: `${owePercentage}%` }} />
            </BalanceBar>
            {!isDesktop && <NextInstallment />}
        </Balance>
    );
};

export default RepaymentBalance;
