import styled from '@emotion/styled';
import { grey150, grey200, grey950, white } from 'theme/colors';
import * as media from 'theme/custom-media';

export const Balance = styled.section`
    display: flex;
    flex-direction: column;
    margin: 0 1.6rem 2.4rem;
    background-color: ${white};
    padding: 1.8rem 2rem 2rem;
    border-radius: var(--global-border-radius);

    ${media.gteMediumMedia} {
        padding: 2.8rem 3.2rem 3.2rem;
        margin: 0;
        border-bottom: 0.1rem solid ${grey150};
    }
`;

export const BalanceBar = styled.div`
    display: flex;
    height: 2.4rem;
    background-color: ${grey200};
    border-radius: 0.3rem;
    overflow: hidden;
    margin: 1rem 0 1.6rem;

    ${media.gteMediumMedia} {
        margin: 1.2rem 0 0;
    }
`;

export const PaidBar = styled.div`
    background: linear-gradient(90deg, #e3ddd1 0%, #3f7607 100%);
    border-radius: 0.3rem;
`;

export const TextContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const TextBox = styled.div`
    color: ${grey950};

    &:last-of-type {
        text-align: right;
    }
`;

export const Title = styled.div`
    font-size: 1.4rem;
    line-height: 1.82rem;
`;

export const Amount = styled.div`
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 500;
    margin-top: 0.4rem;
`;
