import { ReactNode } from 'react';

import styled from '@emotion/styled';
import colours from 'theme/colours/v2';

interface BannerProps {
    children: ReactNode;
    variant: keyof typeof colours;
}

const BannerContainer = styled.div<BannerProps>((props) => ({
    width: '100%',
    backgroundColor: colours[props.variant],
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
}));

const Banner = ({ children, variant }: BannerProps) => {
    return <BannerContainer variant={variant}>{children}</BannerContainer>;
};

export default Banner;
