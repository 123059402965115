import { useState } from 'react';

import { Dialog } from '@material-ui/core';
import IDSearch from 'components/IDSearch';
import Text from 'components/atoms/text';
import { Close } from 'components/icons';
import Logout from 'components/logout/Logout';
import { useTranslation } from 'react-i18next';

import {
    Button,
    CloseButton,
    Container,
    Description,
    DialogContent,
    DialogTitle,
    Header,
    Logo,
    Main,
    ServiceIdHelpImg,
    Title,
} from './styles';

const AdminSearch = () => {
    const { t } = useTranslation('admin');
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <Container>
            <Header>
                <Logo />
                <Logout />
            </Header>
            <Main>
                <Title
                    as='h1'
                    variant='headline3'
                    colour='Steel100'
                    weight='bold'
                >
                    {t('root.title')}
                </Title>
                <Button onClick={() => setModalOpen(true)}>
                    <Text weight='bold' colour='Advance100'>
                        {t('root.serviceIdHelp')}
                    </Text>
                </Button>
                <IDSearch />
            </Main>
            <Dialog
                open={isModalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                maxWidth='lg'
            >
                <DialogTitle>
                    <Text
                        id='modal-modal-title'
                        variant='headline3'
                        as='h2'
                        colour='Steel80'
                        weight='bold'
                    >
                        {t('root.modal.title')}
                    </Text>
                    <CloseButton onClick={() => setModalOpen(false)}>
                        <Close />
                    </CloseButton>
                </DialogTitle>
                <DialogContent>
                    <Description>
                        <Text
                            id='modal-modal-description'
                            variant='paragraphRegular'
                        >
                            {t('root.modal.content')}
                        </Text>
                    </Description>
                    <ServiceIdHelpImg src='/serviceIdHelp.jpeg' alt='' />
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default AdminSearch;
