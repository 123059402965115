import { FC, Fragment } from 'react';

import StatementDetails from 'components/statements/StatementDetails';
import StatementInfo from 'components/statements/StatementInfo';
import { useTranslation } from 'react-i18next';

const ProductDetails: FC = () => {
    const { t } = useTranslation('statement');

    return (
        <Fragment>
            <StatementInfo title={t('key-data')} />
            <StatementDetails title={t('more-details')} />
        </Fragment>
    );
};

export default ProductDetails;
