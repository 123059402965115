import * as yup from 'yup';

export const schema = yup.object().shape({
    code: yup
        .string()
        .trim()
        .max(16, 'Code must be at most 16 characters')
        .required(),
    password: yup
        .string()
        .trim()
        .max(255)
        .required()
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\\^$*.\[\]{}()?"!@#%&/\\,><':;|_~`=+-])[A-Za-z\d\\^$*.\[\]{}()?"!@#%&/\\,><':;|_~`=+-]{8,}$/,
            'Password must contain at least: 8 characters, 1 letter, 1 number, and 1 of the following ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -',
        ),
    confirmPassword: yup
        .string()
        .trim()
        .max(255)
        .required()
        .oneOf(
            [yup.ref('password'), null],
            'Please make sure your passwords match',
        ),
});
