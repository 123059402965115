/* eslint-disable max-len */
import React from 'react';

const HalfIconBackground: React.FC = (props) => (
    <svg viewBox='0 0 513 876' fill='none' {...props}>
        <g opacity='0.25' clipPath='url(#clip0)'>
            <path
                d='M71.6042 -40.2943C55.9185 -42.6471 41.4876 -43.902 26.5862 -45L429.395 193.109C389.671 131.099 337.237 78.229 275.559 37.9934C213.88 -2.24214 144.363 -28.9262 71.6042 -40.2943Z'
                fill='#4D5358'
            />
            <path
                d='M510.49 425.57L-499.197 298.202C-510.785 330.207 -519.294 363.244 -524.608 396.865C-526.804 411.296 -527.902 425.57 -529 439.844L512.843 449.098C512.373 441.256 511.275 433.256 510.49 425.57Z'
                fill='url(#paint0_linear)'
            />
            <path
                d='M438.495 747.441L53.5679 997C132.49 987.439 208.188 959.988 274.895 916.74C341.601 873.493 397.556 815.589 438.495 747.441Z'
                fill='url(#paint1_linear)'
            />
            <path
                d='M469.393 267.616L-117.879 -34.4899C-172.139 -22.9346 -224.196 -2.75794 -272.069 25.2726L485.863 309.497C481.052 295.38 475.562 281.42 469.393 267.616Z'
                fill='#4D5358'
            />
            <path
                d='M-526.648 539.291C-520.922 587.416 -508.515 634.508 -489.787 679.208L512.214 511.214C512.214 503.214 512.214 495.528 512.999 487.372L-526.648 539.291Z'
                fill='url(#paint2_linear)'
            />
            <path
                d='M-92.4693 994.02L475.823 671.836C481.47 657.719 486.332 643.445 491.509 628.857L-249.483 941.63C-200.294 967.403 -147.279 985.092 -92.4693 994.02Z'
                fill='url(#paint3_linear)'
            />
            <path
                d='M-340.771 880.456L502.648 580.388C504.217 572.859 506.099 565.487 507.354 557.644C507.354 555.762 507.354 553.88 507.354 551.997L-441.473 769.244C-413.345 810.899 -379.439 848.344 -340.771 880.456Z'
                fill='url(#paint4_linear)'
            />
            <path
                d='M499.51 357.181L-360.693 90.524C-397.458 124.275 -429.156 163.159 -454.807 205.971L505 385.101C503.274 375.847 501.706 366.435 499.51 357.181Z'
                fill='#4D5358'
            />
        </g>
        <defs>
            <linearGradient
                id='paint0_linear'
                x1='513'
                y1='448.228'
                x2='-293.495'
                y2='383.798'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D5358' stopOpacity='0.72' />
                <stop offset='0.361776' stopColor='#63696D' />
                <stop offset='0.469541' stopColor='#6B7176' />
                <stop
                    offset='0.546814'
                    stopColor='#63696D'
                    stopOpacity='0.76'
                />
                <stop offset='1' stopColor='#4D5358' stopOpacity='0' />
            </linearGradient>
            <linearGradient
                id='paint1_linear'
                x1='450.791'
                y1='763.717'
                x2='-46.8805'
                y2='1079.2'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D5358' stopOpacity='0.54' />
                <stop offset='0.353911' stopColor='#6B7176' />
                <stop offset='1' stopColor='#4D5358' stopOpacity='0' />
            </linearGradient>
            <linearGradient
                id='paint2_linear'
                x1='513'
                y1='487.682'
                x2='-498.628'
                y2='627.861'
                gradientUnits='userSpaceOnUse'
            >
                <stop
                    offset='0.264122'
                    stopColor='#5D6469'
                    stopOpacity='0.63'
                />
                <stop
                    offset='0.411004'
                    stopColor='#70777C'
                    stopOpacity='0.35'
                />
                <stop
                    offset='0.673723'
                    stopColor='#6C747A'
                    stopOpacity='0.17'
                />
                <stop offset='1' stopColor='#5C6369' stopOpacity='0' />
            </linearGradient>
            <linearGradient
                id='paint3_linear'
                x1='490.782'
                y1='647.075'
                x2='-195.363'
                y2='993.713'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D5358' stopOpacity='0.64' />
                <stop offset='0.441143' stopColor='#6B7176' />
                <stop offset='1' stopColor='#4D5358' stopOpacity='0' />
            </linearGradient>
            <linearGradient
                id='paint4_linear'
                x1='513'
                y1='562.648'
                x2='-295.618'
                y2='788.271'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D5358' stopOpacity='0.39' />
                <stop offset='0.486778' stopColor='#6B7176' />
                <stop offset='1' stopColor='#4D5358' stopOpacity='0' />
            </linearGradient>
            <clipPath id='clip0'>
                <rect
                    width='1042'
                    height='1042'
                    fill='white'
                    transform='translate(-529 -45)'
                />
            </clipPath>
        </defs>
    </svg>
);

export default HalfIconBackground;
