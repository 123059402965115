import { FC } from 'react';

import ErrorBoundary from 'components/error-boundary/ErrorBoundary';
import { globalStyles } from 'theme/global';

import AppRoutes from './AppRoutes';
import Providers from './helpers/Providers';

const App: FC = () => {
    return (
        <Providers>
            <ErrorBoundary>
                {globalStyles}
                <AppRoutes />
            </ErrorBoundary>
        </Providers>
    );
};

export default App;
