/* eslint-disable max-len */
import React from 'react';

const SignOut: React.FC = (props) => (
    <svg viewBox='0 0 14 14' fill='none' {...props}>
        <title>Sign Out</title>
        <path
            d='M6.59864 12.614H1.38531V1.38534H6.59864C6.82001 1.38534 6.99965 1.20567 6.99965 0.984328C6.99965 0.762986 6.81998 0.583313 6.59864 0.583313H0.984266C0.762925 0.583313 0.583252 0.762953 0.583252 0.984328V13.0151C0.583252 13.2364 0.762925 13.4161 0.984266 13.4161H6.5986C6.81998 13.4161 6.99962 13.2364 6.99962 13.0151C6.99965 12.7937 6.81998 12.614 6.59864 12.614Z'
            fill='currentColor'
        />
        <path
            d='M13.2997 6.71977L10.5327 3.913C10.3759 3.75459 10.122 3.75499 9.96562 3.913C9.80882 4.07101 9.80882 4.32765 9.96562 4.48565L12.051 6.60106H3.79267C3.57129 6.60106 3.39165 6.78234 3.39165 7.00611C3.39165 7.22989 3.57133 7.41116 3.79267 7.41116H12.051L9.96562 9.52657C9.80882 9.68498 9.80882 9.94122 9.96562 10.0992C10.1224 10.2576 10.3763 10.2576 10.5327 10.0992L13.2993 7.29246C13.4542 7.13645 13.4558 6.87537 13.2997 6.71977Z'
            fill='currentColor'
        />
    </svg>
);

export default SignOut;
