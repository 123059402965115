import { FC, Fragment, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import useProfile from '../../helpers/useProfile';

const WelcomeMessage: FC = () => {
    const { t } = useTranslation('welcome');
    const { firstName } = useProfile();

    const message = useCallback(() => {
        const curHr = new Date().getHours();
        let message = t('welcome.evening');

        if (curHr >= 6 && curHr < 12) {
            message = t('welcome.morning');
        } else if (curHr >= 12 && curHr < 17) {
            message = t('welcome.afternoon');
        }

        return `${message}${firstName ? ', ' + firstName : ''}!`;
    }, [firstName, t]);

    return <Fragment>{message()}</Fragment>;
};

export default WelcomeMessage;
