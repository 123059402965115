import { FC } from 'react';

import RepaymentBalance from 'components/repayment-balance/RepaymentBalance';
import RepaymentsWithdrawals from 'components/repayments-withdrawals/RepaymentsWithdrawals';
import { useTranslation } from 'react-i18next';

import { Title } from './Statements.styles';

const Statements: FC = () => {
    const { t } = useTranslation('dashboard');

    return (
        <div>
            <Title>{t('statements')}</Title>
            <RepaymentBalance />
            <RepaymentsWithdrawals />
        </div>
    );
};

export default Statements;
