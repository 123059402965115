import { FC } from 'react';

import { CuttedIconBackground, LogoSelinaWhite } from 'components/icons';
import LogoMark from 'components/icons/LogoMark';
import useViewport from 'helpers/useViewport';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import appRoutes from 'utils/routes';

import {
    BackgroundIcon,
    HalfLogoBackground,
    LogoMarkContainer,
    StyledContainer,
    StyledDiv,
    StyledLogoLink,
} from './Authentication.styles';
import EmailEnvelope from './EmailEnvelope';
import {
    ConfirmRegistration,
    Login,
    Recover,
    Register,
    VerifyCode,
} from './components';

const Authentication: FC = () => {
    const { gteMedium: isDesktop } = useViewport();
    const { search } = useLocation();
    const isConfirmRegestrationRoute = useRouteMatch(
        appRoutes.confirmRegistration.root,
    );
    const isRegistrationRoute = useRouteMatch(appRoutes.register.root);

    return (
        <main>
            <StyledContainer>
                {isDesktop &&
                    !isConfirmRegestrationRoute &&
                    !isRegistrationRoute && <HalfLogoBackground />}
                <StyledDiv>
                    <StyledLogoLink to={appRoutes.home.root + search}>
                        {isConfirmRegestrationRoute ? (
                            <EmailEnvelope />
                        ) : (
                            <LogoSelinaWhite />
                        )}
                        {(isConfirmRegestrationRoute ||
                            isRegistrationRoute) && (
                            <LogoMarkContainer>
                                <LogoMark />
                            </LogoMarkContainer>
                        )}
                    </StyledLogoLink>
                    <Switch>
                        <Route exact path={appRoutes.login.root}>
                            <Login />
                        </Route>
                        <Route exact path={appRoutes.register.root}>
                            <Register />
                        </Route>
                        <Route exact path={appRoutes.confirmRegistration.root}>
                            <ConfirmRegistration />
                        </Route>
                        <Route exact path={appRoutes.verifyCode.root}>
                            <VerifyCode />
                        </Route>
                        <Route exact path={appRoutes.recover.root}>
                            <Recover />
                        </Route>
                    </Switch>
                </StyledDiv>
                {!isDesktop &&
                    !isConfirmRegestrationRoute &&
                    !isRegistrationRoute && (
                        <BackgroundIcon>
                            <CuttedIconBackground />
                        </BackgroundIcon>
                    )}
            </StyledContainer>
        </main>
    );
};

export default Authentication;
