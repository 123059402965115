import { FC, Fragment } from 'react';

import CircularProgressbar from 'components/circular-progressbar/CircularProgressbar';
import Modal from 'components/withdrawal-modal/Modal';
import useFacility from 'helpers/useFacility';
import { useTranslation } from 'react-i18next';
import { getDayAndMonth, isBeforeToday } from 'utils/dates';
import { GBPFormatter } from 'utils/formatNumber';

import {
    BottomSection,
    CircularContainer,
    HighlightedNumber,
    HighlightedText,
    InfoBox,
    InfoData,
    InfoRow,
    InfoTitle,
    Statement,
    StyledText,
} from './Home.styles';

const Home: FC = () => {
    const { t } = useTranslation(['home', 'statement']);
    const {
        usedFund,
        creditLimit,
        availableFund,
        owePercentage,
        nextPaymentDue,
        interestRate,
        nextPaymentAmount,
        pendingPercentage,
    } = useFacility();

    const statementInfo = () => {
        return (
            <Statement>
                <InfoRow>
                    <InfoBox>
                        <InfoTitle>{t('statement:next-payment-due')}</InfoTitle>
                        <InfoData>
                            {nextPaymentDue && !isBeforeToday(nextPaymentDue)
                                ? getDayAndMonth(nextPaymentDue)
                                : t('statement:not-applicable')}
                        </InfoData>
                    </InfoBox>
                    <InfoBox>
                        <InfoTitle>{t('statement:interest-rate')}</InfoTitle>
                        <InfoData>{`${interestRate}%`}</InfoData>
                    </InfoBox>
                </InfoRow>
                <InfoRow>
                    <InfoBox>
                        <InfoTitle>{t('statement:repayment-amount')}</InfoTitle>
                        <InfoData>{GBPFormatter(nextPaymentAmount)}</InfoData>
                    </InfoBox>
                </InfoRow>
            </Statement>
        );
    };

    return (
        <Fragment>
            <CircularContainer>
                <CircularProgressbar
                    value={owePercentage}
                    pendingPercentage={pendingPercentage}
                >
                    <HighlightedText>{t('owe-amount')}</HighlightedText>
                    <HighlightedNumber>
                        {GBPFormatter(usedFund)}
                    </HighlightedNumber>

                    <StyledText>
                        {t('available-funds', {
                            value: GBPFormatter(availableFund),
                        })}
                    </StyledText>

                    <StyledText>
                        {t('credit-limit', {
                            value: GBPFormatter(creditLimit),
                        })}
                    </StyledText>
                </CircularProgressbar>
            </CircularContainer>

            <BottomSection>
                <Modal />
                {statementInfo()}
            </BottomSection>
        </Fragment>
    );
};

export default Home;
