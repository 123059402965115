import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { ConfirmRegistrationModel } from './model/confirm-registration.model';
import { LoginModel } from './model/login.model';
import { RecoverPasswordModel } from './model/recover-password.model';
import { RegisterModel } from './model/register.model';
import { ResetPasswordModel } from './model/reset-password.model';

export type LoginResult = 'success' | 'failed' | 'needsVerification' | '';

/**
 * Service to manage AWS Cognito authentication.
 */
export class AuthService {
    get registeredUser(): string | null {
        return sessionStorage.getItem('registered_user_info');
    }

    set registeredUser(value: string | null) {
        if (!value) {
            sessionStorage.removeItem('registered_user_info');
        } else {
            sessionStorage.setItem('registered_user_info', value);
        }
    }

    get marketingFlag(): string | null {
        return sessionStorage.getItem('marketing_opt_out_flag');
    }

    set marketingFlag(value: string | null) {
        if (!value) {
            sessionStorage.removeItem('marketing_opt_out_flag');
        } else {
            sessionStorage.setItem('marketing_opt_out_flag', value);
        }
    }

    get recoverEmail(): string | null {
        return sessionStorage.getItem('session_recover_email');
    }

    set recoverEmail(value: string | null) {
        if (!value) {
            sessionStorage.removeItem('session_recover_email');
        } else {
            sessionStorage.setItem('session_recover_email', value);
        }
    }

    get userEmail(): string | null {
        return localStorage.getItem('user_email');
    }

    set userEmail(value: string | null) {
        if (!value) {
            localStorage.removeItem('user_email');
        } else {
            localStorage.setItem('user_email', value);
        }
    }

    // constructor(private readonly logger: LoggerService) {}

    /**
     * Indicates if the user is already logged in.
     */
    isLoggedIn(): Promise<CognitoUserSession | false> {
        return new Promise((resolve: any): void => {
            Auth.currentSession()
                .then((session: CognitoUserSession) => {
                    resolve(session);
                })
                .catch(() => {
                    resolve(false);
                });
        });
    }

    /**
     * Clean up stored data.
     */
    cleanUp(): void {
        this.registeredUser = null;
        this.recoverEmail = null;
        // For disclaimer (top banner) purposes
        // https://selina.atlassian.net/browse/PDP-109
        sessionStorage.removeItem('show_disclaimer');
    }

    /**
     * Executes login request.
     */
    async login(credentials: LoginModel): Promise<LoginResult> {
        try {
            this.userEmail = credentials.email;
            await Auth.signIn(credentials.email, credentials.password);
            this.cleanUp();
            return 'success';
        } catch (error: any) {
            console.log('Error signing in: ', error);
            return error?.code === 'UserNotConfirmedException'
                ? 'needsVerification'
                : 'failed';
        }
    }

    /**
     * Executes logout request.
     */
    async logout(): Promise<void> {
        try {
            this.userEmail = null;
            await Auth.signOut({ global: true });
            this.cleanUp();
            localStorage.clear();
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    }

    /**
     * Sign up a new user.
     */
    async register(value: RegisterModel): Promise<boolean> {
        try {
            await Auth.signUp({
                username: value.email,
                password: value.password,
                attributes: {
                    email: value.email,
                    'custom:isD2CUser': 'true',
                },
            });
            return true;
        } catch (error: any) {
            if (error?.code === 'UsernameExistsException') {
                return false;
            }
            console.log('Error signing in: ', error);
            throw error;
        }
    }

    /**
     * Confirm sign up of a new user.
     */
    async confirmRegistration(
        value: ConfirmRegistrationModel,
    ): Promise<boolean> {
        try {
            await Auth.confirmSignUp(value.email, value.code);
            return true;
        } catch (error) {
            console.log('Error confirming registration: ', error);
            return false;
        }
    }

    /**
     * Resend the confirmation code.
     */
    async resendCode(email: string): Promise<void> {
        try {
            await Auth.resendSignUp(email);
        } catch (error) {
            console.log('Error resending code: ', error);
        }
    }

    /**
     * Request a password recovery for a user.
     */
    async requestPasswordRecovery(
        value: RecoverPasswordModel,
    ): Promise<boolean> {
        try {
            await Auth.forgotPassword(value.email);
            return true;
        } catch (error) {
            console.log('Error requesting password recovery: ', error);
            return false;
        }
    }

    /**
     * Request a password reset for a user.
     */
    async resetPassword(value: ResetPasswordModel): Promise<boolean> {
        try {
            await Auth.forgotPasswordSubmit(
                value.email,
                value.code,
                value.password,
            );
            return true;
        } catch (error) {
            console.log('Error setting new password: ', error);
            return false;
        }
    }
}
