import {
    ChangeEvent,
    FC,
    ForwardedRef,
    Fragment,
    Ref,
    SyntheticEvent,
    forwardRef,
    useState,
} from 'react';

import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgressbar from 'components/circular-progressbar/CircularProgressbar';
import { ArrowLeft, Close } from 'components/icons';
import useFacility from 'helpers/useFacility';
import useViewport from 'helpers/useViewport';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { GBPFormatter } from 'utils/formatNumber';

import ConfirmWithdrawal from '../confirm-withdrawal/ConfirmWithdrawal';

import {
    Amount,
    ArrowButton,
    AvailableFunds,
    CircularContainer,
    CloseButton,
    Disclaimer,
    ModalContent,
    Title,
} from './EnterAmount.styles';

interface ClickProps {
    onCloseModal: () => void;
}

/* eslint-disable no-unused-vars */
interface NumberFormatCustomProps {
    inputRef: Ref<any>;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
/* eslint-enable no-unused-vars */

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
    const { availableFund } = useFacility();

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix='£'
            allowNegative={false}
            isAllowed={({ value }) =>
                parseFloat(value) <= availableFund || !value
            }
            // decimalScale={2}
            // fixedDecimalScale={true}
            type={'tel'}
        />
    );
}

const DisclaimerMsg: FC = () => {
    const { t } = useTranslation('modal');

    return <Disclaimer>{t('disclaimer')}</Disclaimer>;
};

const EnterAmount = forwardRef(
    ({ onCloseModal }: ClickProps, ref: ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation('modal');
        const { gteMedium: isDesktop } = useViewport();
        const [amount, setAmount] = useState<number | null>(null);
        const [openModal, setOpenModal] = useState(false);
        const { availableFund } = useFacility();
        const withdrawalPercentage = ((amount || 0) / availableFund) * 100;

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            setAmount(parseFloat(event.target.value));
        };

        const handleSubmit = (e: SyntheticEvent) => {
            e.preventDefault();
            setOpenModal(true);
        };

        const handleCloseModal = () => {
            setOpenModal(false);
        };

        const handleCloseAllModals = () => {
            setOpenModal(false);
            onCloseModal();
        };

        const inputField = () => {
            return (
                <TextField
                    value={amount}
                    onChange={handleChange}
                    variant={isDesktop ? 'outlined' : 'standard'}
                    name='numberFormat'
                    id='number-format-input'
                    placeholder='£0'
                    InputProps={{
                        inputComponent: NumberFormatCustom as any,
                    }}
                    fullWidth
                    required
                    autoFocus
                />
            );
        };

        return (
            <ModalContent
                ref={ref}
                style={{ display: openModal ? 'none' : 'flex' }}
            >
                {isDesktop && <Title>{t('enter-amount.withdrawal')}</Title>}
                <form onSubmit={handleSubmit} noValidate>
                    {!isDesktop && (
                        <InputLabel htmlFor='number-format-input'>
                            {t('enter-amount.withdrawal')}
                        </InputLabel>
                    )}
                    <CircularContainer>
                        <CircularProgressbar value={withdrawalPercentage}>
                            {isDesktop ? (
                                <Amount>{GBPFormatter(amount || 0)}</Amount>
                            ) : (
                                inputField()
                            )}
                        </CircularProgressbar>
                    </CircularContainer>
                    {isDesktop && (
                        <Fragment>
                            <InputLabel htmlFor='number-format-input'>
                                {t('enter-amount.how-much-to-withdraw')}
                            </InputLabel>
                            {inputField()}
                        </Fragment>
                    )}
                    <AvailableFunds>
                        {t('enter-amount.available-funds', {
                            value: GBPFormatter(availableFund),
                        })}
                    </AvailableFunds>
                    {!isDesktop && <DisclaimerMsg />}
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        fullWidth
                        disabled={!amount}
                    >
                        {t('enter-amount.confirm')}
                    </Button>
                    {isDesktop && <DisclaimerMsg />}
                </form>

                {isDesktop ? (
                    <CloseButton onClick={onCloseModal}>
                        <Close />
                    </CloseButton>
                ) : (
                    <ArrowButton onClick={onCloseModal}>
                        <ArrowLeft />
                    </ArrowButton>
                )}

                <Dialog
                    open={openModal}
                    onClose={handleCloseModal}
                    maxWidth='md'
                    BackdropProps={{
                        style: {
                            backgroundColor: isDesktop
                                ? 'transparent'
                                : 'rgba(245, 245, 245, 0.65)',
                            backdropFilter: isDesktop ? 'none' : 'blur(0.4rem)',
                        },
                    }}
                    fullScreen={!isDesktop}
                    PaperProps={{
                        elevation: 0,
                        square: true,
                        style: {
                            backgroundColor: 'transparent',
                            justifyContent: isDesktop ? 'center' : 'flex-end',
                        },
                    }}
                >
                    <ConfirmWithdrawal
                        amount={amount}
                        onCloseAllModal={handleCloseAllModals}
                    />
                </Dialog>
            </ModalContent>
        );
    },
);

export default EnterAmount;
