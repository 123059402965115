/* eslint-disable max-len */
import React from 'react';

const LogoSelinaWhite: React.FC = () => (
    <svg
        width='125'
        height='36'
        viewBox='0 0 125 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M20.7622 0.161831C20.2413 0.0823866 19.7262 0.0353085 19.2081 0L33.1275 8.23866C31.7562 6.09377 29.9447 4.26457 27.813 2.87217C25.6813 1.47976 23.278 0.555927 20.7622 0.161831Z'
            fill='white'
        />
        <path
            d='M35.9331 16.2506L1.03608 11.8576C0.633653 12.9636 0.337966 14.1056 0.153057 15.2679C0.0765286 15.7622 0.0353209 16.2565 0 16.739L36.0126 17.0656C35.9979 16.7949 35.9596 16.5242 35.9331 16.2506Z'
            fill='white'
        />
        <path
            d='M33.4456 27.3789L20.1414 36.0001C22.869 35.6697 25.4852 34.7214 27.7908 33.2274C30.0963 31.7334 32.0303 29.7331 33.4456 27.3789Z'
            fill='white'
        />
        <path
            d='M34.5148 10.8044L14.2053 0.364883C12.3309 0.763088 10.5323 1.45843 8.87769 2.42455L35.074 12.2492C34.9151 11.7695 34.7385 11.2782 34.5148 10.8044Z'
            fill='white'
        />
        <path
            d='M0.0882568 20.1876C0.285803 21.8464 0.713775 23.4695 1.35981 25.0101L35.9919 19.2166C36.0096 18.94 36.0125 18.6664 36.0184 18.3927L0.0882568 20.1876Z'
            fill='white'
        />
        <path
            d='M15.093 35.8969L34.7373 24.7658C34.9286 24.2803 35.0964 23.786 35.2495 23.2946L9.67126 34.102C11.3709 34.9863 13.2014 35.5923 15.093 35.8969Z'
            fill='white'
        />
        <path
            d='M6.48141 31.9691L35.6506 21.609C35.7036 21.3471 35.7713 21.0911 35.8125 20.8234C35.8125 20.7557 35.8125 20.6939 35.8331 20.6263L3.03174 28.1293C3.99236 29.5673 5.15404 30.8604 6.48141 31.9691Z'
            fill='white'
        />
        <path
            d='M35.5536 13.8971L5.82226 4.70805C4.54617 5.87154 3.44389 7.21225 2.54919 8.68909L35.7449 14.8681C35.6861 14.5385 35.6213 14.2149 35.5536 13.8971Z'
            fill='white'
        />
        <path
            d='M122.149 29V24.17H121.548V18.74C121.548 17.74 121.288 16.99 120.768 16.49C120.268 15.97 119.507 15.71 118.487 15.71C117.946 15.71 117.326 15.72 116.626 15.74C115.945 15.76 115.275 15.79 114.615 15.83C113.954 15.85 113.374 15.88 112.874 15.92V12.8C113.314 12.76 113.804 12.73 114.345 12.71C114.885 12.67 115.445 12.65 116.025 12.65C116.606 12.63 117.146 12.62 117.646 12.62C119.307 12.62 120.678 12.83 121.758 13.25C122.839 13.67 123.649 14.34 124.19 15.26C124.73 16.16 125 17.37 125 18.89V29H122.149ZM116.896 29.42C115.695 29.42 114.655 29.22 113.774 28.82C112.894 28.4 112.213 27.8 111.733 27.02C111.253 26.22 111.013 25.27 111.013 24.17C111.013 23.01 111.293 22.05 111.853 21.29C112.414 20.53 113.204 19.96 114.224 19.58C115.265 19.2 116.486 19.01 117.886 19.01H121.938V21.35H117.826C116.786 21.35 115.985 21.6 115.425 22.1C114.865 22.6 114.585 23.26 114.585 24.08C114.585 24.88 114.865 25.53 115.425 26.03C115.985 26.51 116.786 26.75 117.826 26.75C118.447 26.75 119.027 26.64 119.567 26.42C120.127 26.18 120.588 25.79 120.948 25.25C121.308 24.71 121.508 23.96 121.548 23L122.449 24.17C122.349 25.31 122.068 26.27 121.608 27.05C121.168 27.81 120.548 28.4 119.747 28.82C118.967 29.22 118.016 29.42 116.896 29.42Z'
            fill='white'
        />
        <path
            d='M94.5184 29V12.83H97.3699V19.76H97.0397C97.0397 18.14 97.2498 16.78 97.6701 15.68C98.1103 14.58 98.7706 13.75 99.6511 13.19C100.532 12.61 101.632 12.32 102.953 12.32H103.103C105.084 12.32 106.565 12.95 107.545 14.21C108.546 15.47 109.046 17.32 109.046 19.76V29H105.414V19.28C105.414 18.18 105.094 17.29 104.454 16.61C103.833 15.93 102.973 15.59 101.872 15.59C100.752 15.59 99.8412 15.94 99.1408 16.64C98.4605 17.34 98.1203 18.27 98.1203 19.43V29H94.5184Z'
            fill='white'
        />
        <path
            d='M87.2439 29V12.83H90.8457V29H87.2439ZM84.8727 15.59V12.83H90.8457V15.59H84.8727ZM88.4445 10.61C87.7041 10.61 87.1539 10.42 86.7937 10.04C86.4535 9.64 86.2834 9.14 86.2834 8.54C86.2834 7.94 86.4535 7.45 86.7937 7.07C87.1539 6.67 87.7041 6.47 88.4445 6.47C89.1849 6.47 89.7252 6.67 90.0653 7.07C90.4255 7.45 90.6056 7.94 90.6056 8.54C90.6056 9.14 90.4255 9.64 90.0653 10.04C89.7252 10.42 89.1849 10.61 88.4445 10.61Z'
            fill='white'
        />
        <path
            d='M82.7587 29.18C81.6781 29.18 80.7877 29.04 80.0873 28.76C79.3869 28.46 78.8667 28 78.5265 27.38C78.1863 26.74 78.0162 25.9 78.0162 24.86V7.10001H81.6481V24.95C81.6481 25.31 81.7482 25.59 81.9483 25.79C82.1484 25.99 82.4285 26.09 82.7887 26.09H84.3795V29.18H82.7587Z'
            fill='white'
        />
        <path
            d='M68.5558 29.57C67.155 29.57 65.9344 29.33 64.8939 28.85C63.8534 28.37 62.9929 27.73 62.3126 26.93C61.6522 26.13 61.152 25.24 60.8118 24.26C60.4916 23.26 60.3315 22.24 60.3315 21.2V20.63C60.3315 19.57 60.4916 18.55 60.8118 17.57C61.152 16.57 61.6522 15.67 62.3126 14.87C62.9929 14.07 63.8333 13.44 64.8339 12.98C65.8544 12.5 67.035 12.26 68.3757 12.26C70.1166 12.26 71.5673 12.65 72.7279 13.43C73.8885 14.19 74.7589 15.18 75.3392 16.4C75.9396 17.6 76.2397 18.9 76.2397 20.3V21.74H61.8923V19.34H73.8385L72.8179 20.57C72.8179 19.47 72.6479 18.54 72.3077 17.78C71.9875 17 71.4973 16.4 70.8369 15.98C70.1966 15.54 69.3762 15.32 68.3757 15.32C67.3551 15.32 66.4947 15.56 65.7943 16.04C65.114 16.5 64.6037 17.15 64.2636 17.99C63.9234 18.81 63.7533 19.79 63.7533 20.93C63.7533 22.01 63.9134 22.97 64.2335 23.81C64.5737 24.65 65.104 25.31 65.8244 25.79C66.5447 26.27 67.4552 26.51 68.5558 26.51C69.6763 26.51 70.5868 26.27 71.2872 25.79C71.9875 25.31 72.4277 24.75 72.6078 24.11H75.9396C75.7194 25.21 75.2692 26.17 74.5889 26.99C73.9285 27.81 73.0781 28.45 72.0375 28.91C71.017 29.35 69.8564 29.57 68.5558 29.57Z'
            fill='white'
        />
        <path
            d='M50.2258 29.57C48.3448 29.57 46.744 29.26 45.4233 28.64C44.1226 28.02 43.1221 27.18 42.4218 26.12C41.7414 25.04 41.4012 23.82 41.4012 22.46H45.0631C45.0631 23.08 45.2232 23.69 45.5434 24.29C45.8835 24.87 46.4338 25.35 47.1942 25.73C47.9546 26.11 48.9651 26.3 50.2258 26.3C51.4064 26.3 52.3669 26.14 53.1073 25.82C53.8676 25.48 54.4279 25.03 54.7881 24.47C55.1683 23.91 55.3584 23.3 55.3584 22.64C55.3584 21.82 54.9982 21.15 54.2779 20.63C53.5775 20.09 52.5069 19.76 51.0662 19.64L48.9351 19.46C46.8941 19.28 45.2632 18.65 44.0426 17.57C42.842 16.47 42.2417 15.03 42.2417 13.25C42.2417 11.87 42.5718 10.68 43.2322 9.68C43.8925 8.68 44.813 7.9 45.9936 7.34C47.1742 6.76 48.5449 6.47 50.1057 6.47C51.7065 6.47 53.0972 6.76 54.2779 7.34C55.4785 7.92 56.3989 8.73 57.0393 9.77C57.6996 10.79 58.0298 12 58.0298 13.4H54.3979C54.3979 12.78 54.2378 12.19 53.9177 11.63C53.6175 11.07 53.1473 10.62 52.5069 10.28C51.8866 9.92 51.0862 9.74 50.1057 9.74C49.1652 9.74 48.3848 9.9 47.7645 10.22C47.1442 10.54 46.674 10.97 46.3538 11.51C46.0536 12.05 45.9036 12.63 45.9036 13.25C45.9036 13.97 46.1737 14.61 46.714 15.17C47.2743 15.73 48.1247 16.06 49.2653 16.16L51.3964 16.34C52.9372 16.46 54.2779 16.79 55.4184 17.33C56.559 17.85 57.4395 18.55 58.0598 19.43C58.7001 20.31 59.0203 21.38 59.0203 22.64C59.0203 24 58.6501 25.21 57.9097 26.27C57.1893 27.31 56.1688 28.12 54.8481 28.7C53.5475 29.28 52.0067 29.57 50.2258 29.57Z'
            fill='white'
        />
    </svg>
);

export default LogoSelinaWhite;
