import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { grey200, grey950 } from 'theme/colors';

export const Container = styled.div`
    position: relative;
    line-height: 0;
`;

export const BackCircularProgress = styled(CircularProgress)`
    position: absolute;
    color: ${grey200};
`;

export const FrontCircularProgress = styled(CircularProgress)`
    color: ${grey950};
    animation-duration: 550ms;
`;
