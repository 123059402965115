import DashboardTemplate from 'components/templates/dashboard';
import useViewport from 'helpers/useViewport';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import appRoutes from 'utils/routes';

import Home from './home';
import Dashboard from './home/desktop';
import ProductDetails from './product-details';
import Statements from './statements';

const HomePage = () => {
    const { gteMedium: isDesktop } = useViewport();
    const { path } = useRouteMatch();

    return (
        <DashboardTemplate>
            <Switch>
                <Route exact path={path}>
                    {isDesktop ? <Dashboard /> : <Home />}
                </Route>
                <Route path={appRoutes.home.children.productDetails.root}>
                    <ProductDetails />
                </Route>
                <Route exact path={appRoutes.home.children.statements.root}>
                    <Statements />
                </Route>
                {/*<PrivateRoute exact path={appRoutes.home.propertyValuation.root}>*/}
                {/*    <PropertyValuation />*/}
                {/*</PrivateRoute>*/}
            </Switch>
        </DashboardTemplate>
    );
};

export default HomePage;
