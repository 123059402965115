export const black = '#000';
export const blue = '#3ba0e9';
export const brandyRose = '#B37E81';
export const green100 = '#EFF7E6';
export const green150 = '#047857';
export const green200 = '#059669';
export const green500 = '#5AA80A';
export const green600 = '#3F7607';
export const greenSuccess = '#469E10';
export const grey = '#e5e7eb';
export const grey100 = '#F9F9F9';
export const grey150 = '#F5F5F3';
export const grey2 = '#4f4f4f';
export const grey200 = '#E9E9E7';
export const grey250 = '#6B7280';
export const grey3 = '#828282';
export const grey300 = '#DFDFDF';
export const grey400 = '#E5E7EB';
export const grey450 = '#f3f4f6';
export const grey50 = '#111827';
export const grey500 = '#92989A';
export const grey600 = '#68757A';
export const grey700 = '#444F53';
export const grey900 = '#323333';
export const grey950 = '#202020';
export const lightBlue = '#7dbcea';
export const lima = '#75CE11';
export const oldRose = '#BA7878';
export const orange100 = '#FFF8E9';
export const primary100 = '#FFF0F0';
export const primary300 = '#D90103';
export const primary400 = '#BE2725';
export const primary500 = '#73090A';
export const red = '#a41d23';
export const white = '#fff';
export const wireGray = '#d6d6d6';
export const steel60 = '#898D90';
export const steel80 = '#61676B';
export const alabaster = '#F7F7F7';
export const gradientBG =
    'linear-gradient(180deg,#3f4c53 0%,#2e3b42 53.63%,#232d32 100%)';

// Selina Colour Palette
export const blueSteel = '#3a4146';
export const blueGraphite = '#444f53';
export const redAdvance = '#6a0006';
export const redAdvanceLight = '#79151a';
