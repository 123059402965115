import styled from '@emotion/styled';
import { grey950 } from 'theme/colors';

export const Container = styled.section`
    display: flex;
    height: 100%;
    padding: 2.4rem 2.4rem 3.2rem;
    color: ${grey950};

    & > div {
        flex: 1;
        border-radius: 0.3rem;
        overflow: hidden;

        &:not(:last-child) {
            margin-right: 2.4rem;
        }
    }
`;
