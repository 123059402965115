import { FC, useCallback } from 'react';

import Logout from 'components/logout/Logout';
import NextInstallment from 'components/next-installment/NextInstallment';
import WelcomeMessage from 'components/welcome-message/WelcomeMessage';
import Modal from 'components/withdrawal-modal/Modal';
import useViewport from 'helpers/useViewport';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import appRoutes from 'utils/routes';

import {
    CenteredTitle,
    DesktopContent,
    MobileContent,
    SelinaIcon,
    StyledTitle,
} from './Header.styles';

const Header: FC = () => {
    const { t } = useTranslation('header');
    const { pathname } = useLocation();
    const { gteMedium: isDesktop } = useViewport();

    const title = useCallback(() => {
        switch (pathname) {
            case appRoutes.home.children.productDetails.root:
                return <StyledTitle>{t('title.product-details')}</StyledTitle>;
            case appRoutes.home.children.statements.root:
                return <StyledTitle>{t('title.statements')}</StyledTitle>;
            case appRoutes.home.children.propertyValuation.root:
                return <StyledTitle>{t('title.my-home')}</StyledTitle>;
            default:
                return (
                    <CenteredTitle>
                        <WelcomeMessage />
                    </CenteredTitle>
                );
        }
    }, [pathname, t]);

    return (
        <header>
            {isDesktop ? (
                <DesktopContent>
                    <div>
                        <SelinaIcon />
                        <NextInstallment />
                    </div>
                    <div>
                        <Modal />
                        <Logout />
                    </div>
                </DesktopContent>
            ) : (
                <MobileContent>{title()}</MobileContent>
            )}
        </header>
    );
};

export default Header;
