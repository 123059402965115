import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { LoginResult } from 'shared/auth/auth.service';

import { RootState } from '../buildStore';

import {
    setAuthTokenReducer,
    setImpersonationReducer,
    setLoginResultReducer,
} from './reducers';

export type AuthToken = [string, object, string] | null;

export type SliceState = {
    isLoggedIn: boolean | null;
    loginResult: LoginResult;
    authToken: AuthToken | null;
    impersonation: {
        firstName: string;
        lastName: string;
        servicingId: string;
    } | null;
};

const initialState: SliceState = {
    isLoggedIn: null,
    loginResult: '',
    authToken: null,
    impersonation: null,
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginResult: setLoginResultReducer,
        setAuthToken: setAuthTokenReducer,
        setImpersonation: setImpersonationReducer,
    },
});

export const getIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const getLoginResult = (state: RootState) => state.auth.loginResult;
export const getAuthToken = (state: RootState) => state.auth.authToken;
export const getRoles = (state: RootState): string[] | undefined =>
    get(getAuthToken(state), '[1].cognito:groups');

export const { setLoginResult, setAuthToken, setImpersonation } = slice.actions;

export default slice.reducer;
