import styled from '@emotion/styled';

export const StyledButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > button {
        margin-bottom: 48px;
    }
`;

export const SubtitleContainer = styled.div`
    margin-bottom: 20px;
`;

export const ResendCodeBox = styled.div`
    text-align: center;
`;

export const ResendDesc = styled.p`
    padding-bottom: 8px;
`;

export const TextButton = styled.button`
    color: #fff;
    text-decoration: underline;
    outline: 0;
    cursor: pointer;
    background: none;
    border: none;
    padding: 4px 8px;
`;
