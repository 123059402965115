import { css } from '@emotion/react';

import { family } from '../fonts';

export const fontFamilyNeueSingularDRegular = `${family.neueSingular.standard}, sans-serif, -apple-system`;

export const headline1 = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 8rem;
    font-style: normal;
    line-height: 1.2;
    margin-bottom: 1rem;
`;

export const headline2 = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 4.2rem;
    font-style: normal;
    line-height: 1.2;
`;

export const headline3 = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 2.8rem;
    font-style: normal;
    line-height: 1.2;
`;

export const headline4 = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 4rem;
    font-style: normal;
    line-height: 1.2;
`;

export const paragraphSmall = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 1.6rem;
    font-style: normal;
    line-height: 1.2;
`;

export const paragraphRegular = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 2rem;
    font-style: normal;
    line-height: 1.2;
`;

export const paragraphLarge = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 2.4rem;
    font-style: normal;
    line-height: 1.2;
`;

export const labelRegular = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 1.2rem;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 1.2;
`;

export const labelLarge = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 1.6rem;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 1.2;
`;

const typography = {
    headline1,
    headline2,
    headline3,
    headline4,
    paragraphSmall,
    paragraphRegular,
    paragraphLarge,
    labelRegular,
    labelLarge,
};

export default typography;
