import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles';

import {
    grey100,
    grey300,
    grey500,
    primary500,
    redAdvance,
    redAdvanceLight,
} from './colors';
import typography from './typography';

export const theme = createTheme({
    palette: {
        primary: {
            main: redAdvanceLight,
        },
        secondary: {
            main: grey100,
            contrastText: primary500,
        },
    },
    typography: {
        fontFamily: [
            typography.fontFamilyNeueSingularDRegular,
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 22.4,
    },
    overrides: {
        // Style sheet name
        MuiButton: {
            root: {
                height: '4.8rem',
                borderRadius: '0.2rem',
                textTransform: 'none',
                fontSize: '1.8rem',
                lineHeight: '2.7rem',
                '&$disabled': {
                    color: grey500,
                    backgroundColor: grey300,
                },
            },
            contained: {
                boxShadow: 'none',
                '&$disabled': {
                    color: grey500,
                    backgroundColor: grey300,
                },
            },
            outlinedSecondary: {
                color: redAdvance,
                border: `0.1rem solid ${redAdvance}`,
                boxShadow: 'none',
                '&$disabled': {
                    color: grey500,
                    backgroundColor: grey300,
                },
            },
        },
    },
});
