import { PayloadAction } from '@reduxjs/toolkit';
import { LoginResult } from 'shared/auth/auth.service';

import { AuthToken, SliceState } from './slice';

export const setLoginResultReducer = (
    state: SliceState,
    action: PayloadAction<LoginResult>,
) => {
    state.loginResult = action.payload;
};

export const setAuthTokenReducer = (
    state: SliceState,
    action: PayloadAction<AuthToken | null>,
) => {
    if (action.payload) {
        state.isLoggedIn = true;
        state.authToken = action.payload;
    } else {
        state.isLoggedIn = false;
        state.authToken = null;
    }
};

export const setImpersonationReducer = (
    state: SliceState,
    action: PayloadAction<SliceState['impersonation'] | null>,
) => {
    if (action.payload) {
        state.impersonation = action.payload;
    } else {
        state.impersonation = null;
    }
};
