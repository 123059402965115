import styled from '@emotion/styled';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';

interface StylingProps {
    noSpacing: boolean;
}

export const StyledInputAdornment = styled(InputAdornment)`
    padding-left: 1.6rem;
    margin-right: 0;

    .MuiSvgIcon-root {
        margin-bottom: 0.5rem;
    }
`;

export const StyledEmailTextField = styled(TextField)<StylingProps>`
    .MuiInputBase-input {
        padding: ${(props) =>
            props.noSpacing ? '1.4rem 0.5rem' : '1.4rem 1.6rem'};
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: underline;
`;

export const PasswordHintContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
    margin-top: 12px;
`;

export const StyledList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: disc;
    margin-top: 8px;
`;

export const StyledListItem = styled.li`
    flex: 1 0 auto;
    width: calc(50% - 2rem);
    margin-left: 2rem;
    padding-right: 1rem;
    list-style: disc;
`;

export const LoginLinkContainer = styled.div`
    text-align: center;
    margin-top: 48px;
`;
