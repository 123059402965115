import styled from '@emotion/styled';
import { Button as MuiButton } from '@material-ui/core';
import colours from 'theme/colours/v2';

export const Button = styled(MuiButton)();

export const RoundButton = styled(MuiButton)({
    borderRadius: 8,
    padding: '12px 24px',
    fontWeight: 'bold',
    minWidth: 'initial',
});

export const RoundButtonSmall = styled(MuiButton)({
    borderRadius: 8,
    padding: '6px 12px',
    minWidth: 'inherit',
    height: 'inherit',
    backgroundColor: colours.Neutral100,
});
