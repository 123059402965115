import * as yup from 'yup';

export const schema = yup.object().shape({
    email: yup
        .string()
        .trim()
        .email('Please input a valid email address')
        .required(),
    password: yup.string().trim().max(255).required(),
});
