import { useCallback, useEffect, useState } from 'react';

import useApplicationId from 'helpers/useApplicationId';
import { useDispatch, useSelector } from 'react-redux';
import {
    useGetFacilityQuery,
    usePostFacilityMutation,
} from 'services/facility/facility';
import {
    getAprc,
    getAvailableFund,
    getBankAccountNumber,
    getBankDetails,
    getBankName,
    getCreditLimit,
    getFacility,
    getInterestRate,
    getNextPaymentAmount,
    getNextPaymentDue,
    getNextPaymentDueInDays,
    getOwePercentage,
    getPendingAmount,
    getPendingPercentage,
    getProductDetails,
    getProductFee,
    getPropertyDetails,
    getRemainingPayments,
    getStatements,
    getTerm,
    getTermInYears,
    getUsedFund,
    setFacility,
} from 'state/facility/slice';

const useFacility = () => {
    const dispatch = useDispatch();
    const applicationId = useApplicationId();
    const [facilityError, setFacilityError] = useState(false);

    const { isUninitialized, isLoading, error, data } = useGetFacilityQuery(
        applicationId,
        { skip: !applicationId || facilityError },
    );

    const setFacilityData = useCallback(
        (data) => dispatch(setFacility(data)),
        [dispatch],
    );

    useEffect(() => {
        if (error) {
            setFacilityError(true);
        }
    }, [error]);

    useEffect(() => {
        if (
            !isLoading &&
            !isUninitialized &&
            data &&
            Object.keys(data).length
        ) {
            setFacilityData(data);
        }
    }, [isUninitialized, isLoading, data, setFacilityData]);

    const address = useSelector(getPropertyDetails);
    const aprc = useSelector(getAprc);
    const availableFund = useSelector(getAvailableFund);
    const bankAccountNumber = useSelector(getBankAccountNumber);
    const bankDetails = useSelector(getBankDetails);
    const bankName = useSelector(getBankName);
    const creditLimit = useSelector(getCreditLimit);
    const facility = useSelector(getFacility);
    const interestRate = useSelector(getInterestRate);
    const nextPaymentAmount = useSelector(getNextPaymentAmount);
    const nextPaymentDue = useSelector(getNextPaymentDue);
    const nextPaymentDueInDays = useSelector(getNextPaymentDueInDays);
    const owePercentage = useSelector(getOwePercentage);
    const pendingAmount = useSelector(getPendingAmount);
    const pendingPercentage = useSelector(getPendingPercentage);
    const productDetails = useSelector(getProductDetails);
    const productFee = useSelector(getProductFee);
    const remainingPayments = useSelector(getRemainingPayments);
    const statements = useSelector(getStatements);
    const term = useSelector(getTerm);
    const termInYears = useSelector(getTermInYears);
    const usedFund = useSelector(getUsedFund);

    return {
        address,
        aprc,
        availableFund,
        bankAccountNumber,
        bankDetails,
        bankName,
        creditLimit,
        error,
        facility,
        facilityError,
        interestRate,
        isIdle: isUninitialized,
        isLoading,
        nextPaymentAmount,
        nextPaymentDue,
        nextPaymentDueInDays,
        owePercentage,
        pendingAmount,
        pendingPercentage,
        productDetails,
        productFee,
        remainingPayments,
        statements,
        term,
        termInYears,
        usedFund,
        usePostFacilityMutation,
    };
};

export default useFacility;
