import { createRef, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
// import { getBankIcon } from 'utils/bankIconSelection';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';
import Checkbox from 'components/atoms/inputs/checkbox';
import Text from 'components/atoms/text';
import { ArrowLeft } from 'components/icons';
import Spinner from 'components/loading/spinner/Spinner';
import useApplicationId from 'helpers/useApplicationId';
import useAuth from 'helpers/useAuth';
import useFacility from 'helpers/useFacility';
import useProfile from 'helpers/useProfile';
import useRepaymentAmount from 'helpers/useRepaymentAmount';
import useViewport from 'helpers/useViewport';
import mixpanel from 'mixpanel-browser';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GBPFormatter } from 'utils/formatNumber';
import withdrawalPurpose from 'utils/withdrawalPurpose';
import * as yup from 'yup';

import {
    AgentBox,
    AgentRepaymentBoxHighlight,
    AgentSubtitle,
    AgentTermsBox,
    AgentTermsBoxHighlight,
    AgentTitle, // TopContainer,
    // BankDetails,
    BodyContainer,
    BottomContainer,
    Center,
    FeedbackActions,
    FeedbackBody,
    FeedbackContent,
    FeedbackDescription,
    FeedbackSubTitle,
    FeedbackTitle,
    Heading,
    LeftColumn,
    ModalContainer,
    Overlay,
    RepaymentPlaceholder,
    RepaymentSection,
    RightColumn,
    SelectIconDown,
    SelectIconUp,
    StyledButton,
    StyledDialogActions,
    StyledDialogContent,
    StyledFormControl,
    StyledSpinner,
    SubTitle,
    TermsTitle,
    Title,
    Wrapper,
} from './ConfirmWithdrawal.styles';

interface Props {
    amount: number | null;
    onCloseAllModal: () => void;
}

interface ConfirmWithdrawalForm {
    purpose: string;

    executor: {
        emailAddress: string;
        agentRepaymentConfirmation: boolean;
        agentTermsConfirmation: boolean;
        agentBankDetailsConfirmation: boolean;
    } | null;
}

const ConfirmWithdrawalFormSchema = yup.object({
    purpose: yup.string().required(),
    executor: yup.object({
        emailAddress: yup.string(),
        agentRepaymentConfirmation: yup.boolean().when('emailAddress', {
            is: (val: string) => !!val,
            then: (schema) => schema.required().oneOf([true]),
            otherwise: (schema) => schema,
        }),
        agentTermsConfirmation: yup.boolean().when('emailAddress', {
            is: (val: string) => !!val,
            then: (schema) => schema.required().oneOf([true]),
            otherwise: (schema) => schema,
        }),
        agentBankDetailsConfirmation: yup.boolean().when('emailAddress', {
            is: (val: string) => !!val,
            then: (schema) => schema.required().oneOf([true]),
            otherwise: (schema) => schema,
        }),
    }),
});

const ConfirmWithdrawal = ({ amount, onCloseAllModal }: Props) => {
    const { impersonation } = useAuth();
    const { emailAddress } = useProfile();

    const { control, handleSubmit, formState } = useForm<ConfirmWithdrawalForm>(
        {
            resolver: yupResolver(ConfirmWithdrawalFormSchema),
            defaultValues: {
                purpose: '',
                executor: impersonation
                    ? {
                          emailAddress: emailAddress!,
                          agentTermsConfirmation: false,
                          agentRepaymentConfirmation: false,
                          agentBankDetailsConfirmation: false,
                      }
                    : undefined,
            },
        },
    );

    const { t } = useTranslation(['modal', 'statement']);
    const { gteMedium: isDesktop } = useViewport();
    const [open, setOpen] = useState(false);
    const buttonRef = createRef<HTMLButtonElement>();
    const applicationId = useApplicationId();
    const {
        availableFund,
        // bankAccountNumber,
        // bankName,
        termInYears,
        nextPaymentAmount,
        usePostFacilityMutation,
    } = useFacility();

    const {
        isIdle: isRepaymentIdle,
        isLoading: isRepaymentLoading,
        repaymentAmount,
    } = useRepaymentAmount(amount);

    const isRepaymentAmountFetching = isRepaymentIdle || isRepaymentLoading;
    const remainingFacility = availableFund - (amount || 0);
    // const lastFour = bankAccountNumber?.substring(bankAccountNumber.length - 4);

    const [updatePost, { isLoading, isSuccess, error }] =
        usePostFacilityMutation();

    useEffect(() => {
        if (isSuccess) {
            mixpanel.track('Start Withdrawal', { amount });
        }
    }, [amount, isSuccess]);

    const onSubmit = ({ executor, purpose }: ConfirmWithdrawalForm) => {
        updatePost({
            applicationId,
            body: {
                amount,
                purpose,
                datetime: new Date().toISOString(),
                executor: executor,
            },
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const selectInput = () => {
        return isDesktop ? (
            <Controller
                name='purpose'
                control={control}
                render={({ field, fieldState }) => {
                    return (
                        <StyledFormControl>
                            <Select
                                id='customized-select'
                                open={open}
                                onOpen={handleOpen}
                                onClose={handleClose}
                                input={<InputBase />}
                                displayEmpty
                                IconComponent={() =>
                                    open ? <SelectIconUp /> : <SelectIconDown />
                                }
                                {...field}
                                error={!!fieldState.error}
                            >
                                <MenuItem value={''}>
                                    {t('confirm-withdrawal.select-purpose')}
                                </MenuItem>
                                {withdrawalPurpose.map((value) => {
                                    return (
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </StyledFormControl>
                    );
                }}
            />
        ) : (
            <FormControl>
                <Controller
                    name='purpose'
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <NativeSelect
                                id='customized-select-native'
                                input={<InputBase />}
                                IconComponent={() => <SelectIconDown />}
                                {...field}
                                error={!!fieldState.error}
                            >
                                <option>
                                    {t('confirm-withdrawal.select-purpose')}
                                </option>
                                {withdrawalPurpose.map((value) => {
                                    return (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    );
                                })}
                            </NativeSelect>
                        );
                    }}
                />
            </FormControl>
        );
    };

    if (isSuccess || error) {
        const term = isSuccess ? 'success' : 'error';

        return (
            <FeedbackContent>
                <FeedbackBody>
                    <FeedbackTitle>{t(`${term}.title`)}</FeedbackTitle>
                    <FeedbackSubTitle>{t(`${term}.subtitle`)}</FeedbackSubTitle>
                    <FeedbackDescription>
                        <b>{t(`${term}.description`)}</b>
                        <ul>
                            {[...Array(isSuccess ? 4 : 1)].map((_, i) => (
                                <li key={i}>
                                    {t(`${term}.list-item-${i + 1}`)}
                                </li>
                            ))}
                        </ul>
                        <p>
                            {t(`${term}.link-context`)}
                            <a
                                href={`mailto:myloan@selinafinance.co.uk?subject=${
                                    isSuccess
                                        ? 'Further Questions'
                                        : 'Something went wrong'
                                }`}
                            >
                                {t(`${term}.link`)}
                            </a>
                        </p>
                    </FeedbackDescription>
                </FeedbackBody>
                <FeedbackActions>
                    {impersonation ? (
                        <>
                            <Link to='/admin'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    fullWidth
                                >
                                    {t('success.confirmCustomer')}
                                </Button>
                            </Link>
                            <Button
                                onClick={onCloseAllModal}
                                variant='contained'
                                color='primary'
                                type='submit'
                                fullWidth
                            >
                                {t('success.returnToDashboard')}
                            </Button>
                        </>
                    ) : (
                        <Button
                            innerRef={buttonRef}
                            onClick={onCloseAllModal}
                            variant='contained'
                            color='primary'
                            type='submit'
                            fullWidth
                        >
                            {t('success.done')}
                        </Button>
                    )}
                </FeedbackActions>
            </FeedbackContent>
        );
    }

    return (
        <>
            <ModalContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        {impersonation && (
                            <RightColumn>
                                <AgentTitle variant='headline3' as='h2'>
                                    {t(
                                        'confirm-withdrawal.agent-checklist.title',
                                    )}
                                </AgentTitle>
                                <AgentSubtitle variant='paragraphSmall'>
                                    {t(
                                        'confirm-withdrawal.agent-checklist.subtitle',
                                    )}
                                </AgentSubtitle>
                                <AgentBox>
                                    {isRepaymentAmountFetching ? (
                                        <>
                                            <Center>
                                                <Spinner />
                                            </Center>
                                        </>
                                    ) : (
                                        <>
                                            <AgentRepaymentBoxHighlight>
                                                {!!repaymentAmount && (
                                                    <>
                                                        <Text
                                                            variant='paragraphSmall'
                                                            as='h3'
                                                            weight='bold'
                                                            colour='Steel80'
                                                        >
                                                            {t(
                                                                'confirm-withdrawal.agent-checklist.estimated',
                                                            )}
                                                        </Text>
                                                        <p>
                                                            {!!nextPaymentAmount && (
                                                                <>
                                                                    <span>
                                                                        {GBPFormatter(
                                                                            nextPaymentAmount,
                                                                            2,
                                                                        )}
                                                                    </span>
                                                                    <ArrowLeft />
                                                                </>
                                                            )}
                                                            <span>
                                                                {GBPFormatter(
                                                                    repaymentAmount,
                                                                    2,
                                                                )}
                                                            </span>
                                                        </p>
                                                    </>
                                                )}
                                            </AgentRepaymentBoxHighlight>
                                            <Checkbox
                                                name='executor.agentRepaymentConfirmation'
                                                control={control}
                                                label={
                                                    <Text
                                                        as='span'
                                                        variant='paragraphSmall'
                                                    >
                                                        {t(
                                                            'confirm-withdrawal.agent-checklist.confirmReypayment',
                                                        )}
                                                    </Text>
                                                }
                                            />
                                        </>
                                    )}
                                </AgentBox>
                                <AgentTermsBox>
                                    <AgentTermsBoxHighlight>
                                        <TermsTitle
                                            variant='paragraphSmall'
                                            weight='bold'
                                            as='h3'
                                        >
                                            {t(
                                                'confirm-withdrawal.agent-checklist.terms.title',
                                            )}
                                        </TermsTitle>
                                        <Text variant='paragraphSmall'>
                                            {t(
                                                'confirm-withdrawal.agent-checklist.terms.body',
                                            )}
                                        </Text>
                                        <Text variant='paragraphSmall'>
                                            {t(
                                                'confirm-withdrawal.agent-checklist.terms.body2',
                                            )}
                                        </Text>
                                    </AgentTermsBoxHighlight>
                                    <Checkbox
                                        name='executor.agentTermsConfirmation'
                                        control={control}
                                        label={
                                            <Text
                                                as='span'
                                                variant='paragraphSmall'
                                            >
                                                {t(
                                                    'confirm-withdrawal.agent-checklist.confirmTerms',
                                                )}
                                            </Text>
                                        }
                                    />
                                </AgentTermsBox>
                                <AgentTermsBox>
                                    <AgentTermsBoxHighlight>
                                        <TermsTitle
                                            variant='paragraphSmall'
                                            weight='bold'
                                            as='h3'
                                        >
                                            {t(
                                                'confirm-withdrawal.agent-checklist.bank.title',
                                            )}
                                        </TermsTitle>
                                        <Text variant='paragraphSmall'>
                                            {t(
                                                'confirm-withdrawal.agent-checklist.bank.body',
                                            )}
                                        </Text>
                                    </AgentTermsBoxHighlight>
                                    <Checkbox
                                        name='executor.agentBankDetailsConfirmation'
                                        control={control}
                                        label={
                                            <Text
                                                as='span'
                                                variant='paragraphSmall'
                                            >
                                                {t(
                                                    'confirm-withdrawal.agent-checklist.confirmBankDetails',
                                                )}
                                            </Text>
                                        }
                                    />
                                </AgentTermsBox>
                            </RightColumn>
                        )}
                        <LeftColumn isImpersonating={!!impersonation}>
                            <StyledDialogContent>
                                {isDesktop && (
                                    <>
                                        <Title>
                                            {t('confirm-withdrawal.withdrawal')}
                                        </Title>
                                        <SubTitle>
                                            {t(
                                                'confirm-withdrawal.confirm-transaction',
                                            )}
                                        </SubTitle>
                                    </>
                                )}
                                <Heading>
                                    {t('confirm-withdrawal.withdrawal-purpose')}
                                </Heading>
                                {selectInput()}
                                {/* {bankName && lastFour && (
                                    <>
                                        <Heading>
                                            {t(
                                                'confirm-withdrawal.bank-details'
                                            )}
                                        </Heading>
                                        <TopContainer>
                                            <div>{getBankIcon(bankName)}</div>
                                            <BankDetails>
                                                <p>{bankName}</p>
                                                <p>{`****${lastFour}`}</p>
                                            </BankDetails>
                                        </TopContainer>
                                    </>
                                )} */}
                                <Heading>
                                    {t(
                                        'confirm-withdrawal.your-withdrawal-details',
                                    )}
                                </Heading>
                                <BodyContainer>
                                    <p>
                                        <span>
                                            {t('confirm-withdrawal.amount')}
                                        </span>
                                        <span>{GBPFormatter(amount || 0)}</span>
                                    </p>
                                    <p>
                                        <span>
                                            {t(
                                                'confirm-withdrawal.remaining-facility',
                                            )}
                                        </span>
                                        <span>
                                            {GBPFormatter(remainingFacility)}
                                        </span>
                                    </p>
                                    {!!repaymentAmount && (
                                        <p>
                                            <span>
                                                {t(
                                                    'confirm-withdrawal.additional-monthly-payment',
                                                )}
                                            </span>
                                            <span>
                                                {GBPFormatter(
                                                    repaymentAmount -
                                                        nextPaymentAmount,
                                                    2,
                                                )}
                                            </span>
                                        </p>
                                    )}
                                    <p>
                                        <span>
                                            {t(
                                                'confirm-withdrawal.remaining-term',
                                            )}
                                        </span>
                                        <span>
                                            {termInYears}{' '}
                                            {t('statement:years', {
                                                count: termInYears,
                                            })}
                                        </span>
                                    </p>
                                </BodyContainer>
                                {isRepaymentAmountFetching && (
                                    <RepaymentPlaceholder
                                        isImpersonating={!!impersonation}
                                    >
                                        <Center>
                                            <Spinner />
                                        </Center>
                                    </RepaymentPlaceholder>
                                )}
                                {!!repaymentAmount && (
                                    <>
                                        <Heading>
                                            {t('confirm-withdrawal.repayments')}
                                        </Heading>
                                        <BottomContainer>
                                            <RepaymentSection>
                                                <p>
                                                    {t(
                                                        'confirm-withdrawal.new-monthly-payment',
                                                    )}
                                                </p>
                                                <p>
                                                    {!!nextPaymentAmount && (
                                                        <>
                                                            <span>
                                                                {GBPFormatter(
                                                                    nextPaymentAmount,
                                                                    2,
                                                                )}
                                                            </span>
                                                            <ArrowLeft />
                                                        </>
                                                    )}
                                                    <span>
                                                        {GBPFormatter(
                                                            repaymentAmount,
                                                            2,
                                                        )}
                                                    </span>
                                                </p>
                                            </RepaymentSection>
                                            <p>
                                                {t(
                                                    'confirm-withdrawal.disclaimer',
                                                )}
                                            </p>
                                        </BottomContainer>
                                    </>
                                )}
                                {isLoading && (
                                    <>
                                        <Overlay />
                                        {!isDesktop && (
                                            <StyledSpinner>
                                                <Spinner />
                                            </StyledSpinner>
                                        )}
                                    </>
                                )}
                            </StyledDialogContent>
                            <StyledDialogActions>
                                <StyledButton
                                    innerRef={buttonRef}
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    disabled={
                                        isLoading ||
                                        isRepaymentAmountFetching ||
                                        !formState.isValid
                                    }
                                >
                                    {isLoading && isDesktop ? (
                                        <Spinner />
                                    ) : (
                                        t('confirm-withdrawal.start-withdrawal')
                                    )}
                                </StyledButton>
                            </StyledDialogActions>
                        </LeftColumn>
                    </Wrapper>
                </form>
            </ModalContainer>
        </>
    );
};

export default ConfirmWithdrawal;
