import styled from '@emotion/styled';
import { green600, grey700, grey950 } from 'theme/colors';

export const Container = styled.section`
    position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    padding: 1.6rem;
    color: ${grey950};
`;

export const Title = styled.p`
    font-size: 2.4rem;
    line-height: 115%;
    margin-bottom: 1rem;
`;

export const Paragraph = styled.p`
    font-size: 1.7rem;
    line-height: 120%;
    color: ${grey700};
`;

export const StyledLink = styled.a`
    display: block;
    font-size: 1.7rem;
    line-height: 120%;
    color: ${green600};
    margin-top: 1rem;
    font-weight: bold;
`;

export const Icon = styled.div({
    width: '4rem',
    margin: '0 auto 0.5rem',
});
