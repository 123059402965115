import styled from '@emotion/styled';
import { DialogContent, FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Text from 'components/atoms/text';
import { ChevronDown } from 'components/icons';
import {
    alabaster,
    blueSteel,
    grey200,
    grey250,
    grey600, // grey3,
    grey950,
    primary300,
    redAdvance,
    steel60,
    steel80,
    white,
} from 'theme/colors';
import colours from 'theme/colours/v2';
import * as media from 'theme/custom-media';

export const RepaymentSection = styled.div`
    padding: 1.6rem;
    background-color: ${alabaster};
    text-align: center;
    color: ${steel80};
    margin-bottom: 1.6rem;

    & p {
        font-size: 1.6rem;
        line-height: 1.92rem;
        font-weight: bold;

        &:last-of-type {
            margin-top: 0.4rem;

            & span {
                font-size: 2.4rem;
                line-height: 2.88rem;
                text-decoration-line: line-through;

                &:last-of-type {
                    font-weight: bolder;
                    color: ${blueSteel};
                    text-decoration: none;
                }
            }
        }
    }

    & svg {
        height: 1.4rem;
        width: 2.5rem;
        margin: 0 0.7rem;
        transform: rotate(180deg);
        display: inline-block;
    }

    ${media.gteMediumMedia} {
        margin-bottom: 1.4rem;

        & p {
            line-height: 2.24rem;

            &:last-of-type {
                margin-top: 1rem;

                & span {
                    font-size: 2.8rem;
                    line-height: 3.36rem;
                }
            }
        }

        & svg {
            width: 1.385rem;
            margin: 0 1.182rem;
        }
    }
`;

export const ModalContainer = styled.div`
    overflow-y: auto;
    max-height: 100%;
    display: flex;

    & .MuiInputBase-root {
        margin: 1.9rem 2.8rem;
        font-size: 2rem;
        color: ${colours.Steel100};
    }
`;

export const Wrapper = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100%;

    ${media.gteMediumMedia} {
        flex-direction: row-reverse;
    }
`;

export const ModalColumn = styled.div({
    backgroundColor: '#fff',
});

export const Center = styled.div({
    textAlign: 'center',
});

export const LeftColumn = styled(ModalColumn)<{ isImpersonating: boolean }>`
    display: 'flex';
    flex-direction: 'column';

    ${media.gteMediumMedia} {
        overflow-y: auto;
        border-radius: 8px;
        max-width: ${(props) => (props.isImpersonating ? '460px' : '606px')};
    }
`;

export const StyledFormControl = styled(FormControl)({
    width: '100%',
});

export const RightColumn = styled(ModalColumn)`
    padding: 2.4rem 2.8rem;
    background-color: ${colours.Neutral40};
    text-align: center;

    ${media.gteMediumMedia} {
        overflow-y: auto;
        border-radius: 8px;
        max-width: 460px;
    }
`;

export const AgentTitle = styled(Text)({
    paddingBottom: 2,
});

export const AgentSubtitle = styled(Text)({
    paddingBottom: '2.5rem',
});

export const AgentBox = styled.div({
    backgroundColor: colours.White,
    color: colours.Steel100,
    border: `solid 1px ${colours.Steel100}`,
    padding: 16,
    borderRadius: 8,
    textAlign: 'left',
});

export const AgentRepaymentBoxHighlight = styled(RepaymentSection)({
    backgroundColor: colours.Neutral40,
    borderRadius: 8,
    marginBottom: 14,
});

export const AgentTermsBoxHighlight = styled.div`
    background-color: ${colours.Neutral40};
    border-radius: 8px;
    padding: 2.4rem;
    margin-bottom: 14px;

    & p + p {
        margin-top: 8px;
    }
`;

export const AgentTermsBox = styled(AgentBox)({
    marginTop: 8,
});

export const TermsTitle = styled(Text)({
    paddingBottom: 8,
});

export const StyledDialogContent = styled(DialogContent)`
    padding: 8px 0 0 0;

    ${media.gteMediumMedia} {
        padding: 8px 10px 0;
    }
`;

export const StyledDialogActions = styled(DialogActions)`
    justify-content: center;
    padding-bottom: 2rem;

    ${media.gteMediumMedia} {
        padding-left: 28px;
        padding-right: 28px;
    }
`;

export const StyledButton = styled(Button)`
    width: 100%;

    ${media.gteMediumMedia} {
        maxwidth: 34.3rem;
    }
`;

export const Heading = styled.div`
    display: flex;
    align-items: center;
    height: 4.8rem;
    padding: 0 1.6rem;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.92rem;
    color: ${redAdvance};
    border-bottom: 0.1rem solid ${grey200};

    &:first-of-type {
        &:after {
            content: '*';
            color: ${primary300};
        }
    }

    ${media.gteMediumMedia} {
        font-size: 1.8rem;
        font-weight: normal;
        line-height: 2.52rem;
        color: ${grey950};
    }
`;

// export const TopContainer = styled.div`
//     display: flex;
//     align-items: center;
//     padding: 1rem 2rem 1.35rem;

//     & svg {
//         width: 3.2rem;
//         margin-right: 1.6rem;
//     }

//     ${media.gteMediumMedia} {
//         padding: 2rem 2.4rem 1.3rem;

//         & svg {
//             margin-right: 2rem;
//         }
//     }
// `;

// export const BankDetails = styled.div`
//     & > p {
//         font-size: 1.6rem;
//         line-height: 1.92rem;
//         color: ${grey950};

//         ${media.gteMediumMedia} {
//             font-size: 1.8rem;
//             line-height: 2.7rem;
//         }

//         &:last-child {
//             font-size: 1.4rem;
//             line-height: 1.82rem;
//             color: ${grey3};
//             margin-top: 0.4rem;

//             ${media.gteMediumMedia} {
//                 margin-top: 0.7rem;
//             }
//         }
//     }
// `;

export const BodyContainer = styled.div`
    margin: 1.3rem 1.6rem 1.75rem;
    color: ${steel60};

    & > p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.6rem;
        line-height: 1.92rem;
        padding: 0.8rem 0;

        &:first-of-type {
            font-size: 2rem;
            line-height: 2.4rem;
            font-weight: bold;
            padding: 0 0 0.65rem;
            color: ${blueSteel};
        }

        &:not(:first-of-type) {
            border-bottom: 0.1rem solid ${grey200};
        }

        &:last-of-type {
            border: none;
            padding-bottom: 0;
        }
    }

    ${media.gteMediumMedia} {
        margin: 0.35rem 1.6rem 1rem;

        & > p {
            font-size: 1.8rem;
            line-height: 2.7rem;
            padding: 0.7rem 0;
            color: ${grey950};

            &:not(:first-of-type) {
                border: none;
            }

            &:first-of-type {
                font-size: 1.8rem;
                line-height: 2.7rem;
            }
        }
    }
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${white};
    opacity: 0.5;
`;

export const StyledSpinner = styled.div`
    position: absolute;
    top: 1.25rem;
    right: 1.7rem;
`;

export const FeedbackContent = styled.div`
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    max-height: 100vh;
    outline: none;
    background-color: ${colours.White};
    height: 100%;
    padding: 45px 40px 24px;

    ${media.gteMediumMedia} {
        width: 400px;
        border-radius: 8px;
    }
`;

export const FeedbackBody = styled.div`
    margin: 9rem auto 16px;
    flex-grow: 1;

    ${media.gteMediumMedia} {
        margin-top: 0;
    }
`;

export const FeedbackTitle = styled.p`
    font-size: 3.4rem;
    line-height: 3.74rem;
    color: ${grey950};
`;

export const FeedbackSubTitle = styled.p`
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: ${grey600};
    margin-top: 1rem;
`;

export const FeedbackDescription = styled.div`
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${grey600};
    margin-top: 3rem;

    & li {
        list-style-type: disc;
        margin-left: 2.3rem;
        margin-top: 1rem;

        &:last-child {
            margin-bottom: 1rem;
        }
    }

    & a {
        font-weight: bold;
    }

    & b {
        font-size: 1.8rem;
        line-height: 2.7rem;
    }
`;

export const FeedbackActions = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
});

export const SelectIconDown = styled(ChevronDown)`
    position: absolute;
    pointer-events: none;
    right: 0;
    width: 1rem;
    color: ${grey250};
`;

export const SelectIconUp = styled(SelectIconDown)`
    transform: rotate(180deg);
`;

export const Title = styled.p`
    font-size: 2.8rem;
    line-height: 3.36rem;
    color: ${grey950};
    margin-bottom: 0.8rem;
    text-align: center;
`;

export const SubTitle = styled.p`
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${grey600};
    text-align: center;
`;

export const ScrollContainer = styled.div`
    // overflow: auto;
`;

export const BottomContainer = styled.div`
    padding: 1.6rem;

    & > p {
        font-size: 1.6rem;
        line-height: 1.7rem;
        margin-bottom: 1.8rem;
        color: ${steel80};

        ${media.gteMediumMedia} {
            margin-bottom: 0.9rem;
        }
    }
`;

export const RepaymentPlaceholder = styled.div<{ isImpersonating: boolean }>`
    width: 100%;
    padding: 1.6rem;

    ${media.gteMediumMedia} {
        width: ${(props) => (props.isImpersonating ? '425px' : '586px')};
    }
`;
