import styled from '@emotion/styled';
import { Card, Lock } from 'components/icons';
import {
    green500,
    grey200,
    grey700,
    grey900,
    grey950,
    white,
} from 'theme/colors';

export const Container = styled.div`
    color: ${grey950};
`;

export const CircularContainer = styled.div`
    max-width: 27.3rem;
    margin: 0 auto;
    line-height: 0;
`;

export const HighlightedText = styled.p`
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${grey700};
`;

export const HighlightedNumber = styled.p`
    font-size: 3rem;
    line-height: 4.5rem;
`;

export const WelcomeContent = styled.div`
    font-size: 3.6rem;
    line-height: 3.96rem;
    margin: 3.4rem 0 3rem;
    text-align: center;
`;

export const BalanceSection = styled.div`
    display: flex;
    max-width: 27.3rem;
    margin: 2.4rem auto 0;
`;

export const BalanceInfo = styled.div`
    display: flex;
    flex-flow: column;
    flex: 1;
    align-items: center;
    justify-content: center;

    & p {
        font-size: 1.8rem;
        line-height: 2.7rem;
        text-align: center;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    background-color: ${white};
    border-radius: 50%;
    margin-bottom: 1.4rem;
`;

export const CardIcon = styled(Card)`
    width: 1.333rem;
    color: ${green500};
`;

export const LockIcon = styled(Lock)`
    width: 1.068rem;
    color: ${grey700};
`;

export const Statement = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.8rem 0;
    margin: 2.6rem auto 0;
    max-width: 27.3rem;
    border-top: 0.1rem solid ${grey200};
`;

export const InfoRow = styled.div`
    display: flex;

    &:not(:last-of-type) {
        margin-bottom: 2.6rem;
    }
`;

export const InfoBox = styled.div`
    color: ${grey900};

    &:not(:last-of-type) {
        margin-right: 4.7rem;
    }
`;

export const InfoTitle = styled.p`
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 0.2rem;
`;

export const InfoData = styled.p`
    font-size: 2rem;
    line-height: 3rem;
`;
