import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';

import styled from '@emotion/styled';
import colors from 'theme/colours/v2';
import typography from 'theme/typography/v2';

export interface TextProps {
    children: ReactNode;
    variant?: Exclude<
        keyof typeof typography,
        'weight' | 'fontFamilyNeueSingularDRegular'
    >;
    colour?: keyof typeof colors;
    weight?: 'normal' | 'bold';
    uppercase?: boolean;
    underline?: boolean;
}

const TextElement = styled.p<TextProps>(
    (props) => ({
        ...typography[props.variant || 'paragraphRegular'],
    }),
    (props) => {
        const textColor = colors[props.colour || 'Steel100'];
        return {
            color: textColor,
            fontWeight: props.weight || 'normal',
            textTransform: props.uppercase ? 'uppercase' : undefined,
            textDecoration: props.underline ? 'underline' : undefined,
            textDecorationColor: props.underline
                ? textColor || 'Steel100'
                : undefined,
        };
    },
);

const Text = <Ctype extends ElementType = 'p'>({
    children,
    ...props
}: TextProps & { as?: Ctype } & Omit<
        ComponentPropsWithoutRef<Ctype>,
        keyof TextProps
    >) => {
    return <TextElement {...props}>{children}</TextElement>;
};

export default Text;
