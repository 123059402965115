import { ReactElement } from 'react';

import styled from '@emotion/styled';
import { CheckboxProps, Checkbox as MuiCheckbox } from '@material-ui/core';
import { Control, Controller, FieldPath } from 'react-hook-form';

interface CheckBoxFieldProps<T extends Record<string, any>> {
    name: FieldPath<T>;
    control: Control<T>;
    label: ReactElement | string;
    InputProps?: CheckboxProps;
}

const Label = styled.label({
    display: 'inline-flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginLeft: -11,
    marginRight: 16,
});

const Checkbox = <T extends Record<string, any>>({
    name,
    control,
    label,
    InputProps,
}: CheckBoxFieldProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <Label>
                        <MuiCheckbox
                            checked={field.value}
                            color='primary'
                            {...field}
                            {...InputProps}
                        />
                        {label}
                    </Label>
                );
            }}
        />
    );
};

export default Checkbox;
