export const Steel100 = '#3a4146';
export const Steel80 = '#61676B';
export const Steel60 = '#898D90';
export const Steel40 = '#B0B3B5';

export const Advance100 = '#6A0006';
export const Advance80 = '#883338';
export const Advance60 = '#A6666A';
export const Advance40 = '#C3999B';

export const Neutral100 = '#E3DDD1';
export const Neutral80 = '#E9E4DA';
export const Neutral60 = '#EEEBE3';
export const Neutral40 = '#F4F1ED';

export const Highlight = '#FEE2AB';
export const Lowlight = '#FFF8E9';
export const Success = '#469E10';
export const Error = '#ff0000';

export const White = '#fff';
export const Black = '#000';

const colours = {
    Steel100,
    Steel80,
    Steel60,
    Steel40,
    Advance100,
    Advance80,
    Advance60,
    Advance40,
    Neutral100,
    Neutral80,
    Neutral60,
    Neutral40,
    Highlight,
    Lowlight,
    Success,
    Error,
    White,
    Black,
};

export default colours;
