import { FC } from 'react';

import useFacility from 'helpers/useFacility';
import { useTranslation } from 'react-i18next';
import { getDayAndMonth, isBeforeToday } from 'utils/dates';
import { GBPFormatter } from 'utils/formatNumber';

import { Container } from './NextInstallment.styles';

const NextInstallment: FC = () => {
    const { t } = useTranslation('nextInstallment');
    const { nextPaymentDue, nextPaymentAmount, isLoading, isIdle } =
        useFacility();
    const formattedDate = getDayAndMonth(nextPaymentDue);

    if (
        isLoading ||
        isIdle ||
        !nextPaymentDue ||
        isBeforeToday(nextPaymentDue) ||
        !nextPaymentAmount
    ) {
        return null;
    }

    return (
        <Container>
            {t('next-installment', {
                text: `${formattedDate}:`,
            })}
            <span>{GBPFormatter(nextPaymentAmount)}</span>
        </Container>
    );
};

export default NextInstallment;
