import styled from '@emotion/styled';
import { white } from 'theme/colors';

export const Container = styled.div`
    background-color: ${white};
    padding-bottom: 3.2rem;
    border-radius: 0.3rem;
`;

export const Title = styled.p`
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 2.6rem 3.2rem 0;
`;
