import { useMemo } from 'react';

import useProfile from './useProfile';
import useQueryParams from './useQueryParams';

// A custom hook that retrieves you the correct application id.
function useApplicationId() {
    const { applicationId } = useProfile();
    const query = useQueryParams();

    const queryAppId = useMemo(() => query.get('applicationId'), [query]);

    return queryAppId ? queryAppId : applicationId || '';
}

export default useApplicationId;
