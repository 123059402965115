import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Text from 'components/atoms/text';
import Loading from 'components/loading/Loading';
import useAuth from 'helpers/useAuth';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ConfirmRegistrationModel } from 'shared/auth/model/confirm-registration.model';
import { RegisterModel } from 'shared/auth/model/register.model';
import appRoutes from 'utils/routes';

import {
    InputsContainer,
    StyledAlert,
    StyledButton,
    StyledForm,
} from '../../Authentication.styles';

import {
    ResendCodeBox,
    ResendDesc,
    StyledButtonsContainer,
    SubtitleContainer,
    TextButton,
} from './ConfirmRegistration.styles';
import { schema } from './schema';

const ConfirmRegistration: FC = () => {
    const { t } = useTranslation('authentication');
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [marketingOptOut, setMarketingOptOut] = useState(true);
    const [isResending, setIsResending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm<ConfirmRegistrationModel>({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        mode: 'onChange',
    });
    const {
        actions: { login, setLoginResult },
        loginResult,
        authService,
    } = useAuth();

    useEffect(() => {
        return () => {
            setLoginResult('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!authService.registeredUser) {
            history.push(appRoutes.login.root);
        } else {
            const model: RegisterModel = JSON.parse(
                atob(authService.registeredUser),
            ) as RegisterModel;
            setEmail(model.email);
            setPassword(model.password);
            setMarketingOptOut(!model.marketing);
        }
    }, [authService.registeredUser, history]);

    useEffect(() => {
        if (loginResult === 'success') {
            history.push(appRoutes.home.root);
        }
    }, [history, loginResult, setLoginResult]);

    const onSubmit = useCallback(
        (data: ConfirmRegistrationModel) => {
            setIsLoading(true);
            setHasError(false);

            const value: ConfirmRegistrationModel = {
                ...data,
                email,
            };
            authService.confirmRegistration(value).then((result: boolean) => {
                if (result) {
                    authService.registeredUser = null;
                    authService.marketingFlag = btoa(
                        JSON.stringify(marketingOptOut),
                    );
                    login({
                        email: email,
                        password: password,
                    });
                } else {
                    setIsLoading(false);
                    setHasError(true);
                }
            });
        },
        [authService, email, login, marketingOptOut, password],
    );

    /**
     * Resend the confirmation code.
     */
    const resendCode = useCallback(() => {
        if (isResending) {
            return;
        }
        setIsResending(true);
        authService.resendCode(email).then(() => {
            setIsResending(false);
        });
    }, [authService, email, isResending]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>): void => {
            // Ignore space key
            if (event.key === ' ') {
                event.preventDefault();
            }

            hasError && isValid && setHasError(false);
        },
        [hasError, isValid],
    );

    return (
        <div>
            <Text as='h1' colour='White' weight='bold' variant='headline3'>
                {t('confirm.title')}
            </Text>
            <SubtitleContainer>
                <p>
                    {t('confirm.sub-title.first')}
                    <Text
                        as='span'
                        colour='White'
                        weight='bold'
                        variant='paragraphSmall'
                    >
                        {email}
                    </Text>
                    {t('confirm.sub-title.second')}
                </p>
            </SubtitleContainer>

            {hasError && (
                <StyledAlert severity='error'>
                    {t('confirm.form.error')}
                </StyledAlert>
            )}

            <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
                <InputsContainer>
                    <TextField
                        {...register('code')}
                        id='code'
                        placeholder={t('confirm.form.code')}
                        type='text'
                        error={!!errors.code}
                        helperText={
                            errors?.code?.type === 'max' &&
                            errors?.code?.message
                        }
                        fullWidth
                        onKeyDown={handleKeyDown}
                    />
                </InputsContainer>
                <StyledButtonsContainer>
                    <StyledButton
                        variant='contained'
                        color='primary'
                        type='submit'
                        fullWidth
                        disabled={isLoading || !isDirty || !isValid}
                    >
                        {isLoading ? <Loading /> : t('confirm.form.submit')}
                    </StyledButton>

                    <ResendCodeBox>
                        <ResendDesc>
                            {t('confirm.form.resend.description')}
                        </ResendDesc>
                        {isResending ? (
                            <Loading />
                        ) : (
                            <TextButton
                                onClick={resendCode}
                                disabled={isResending}
                            >
                                <Text
                                    as='span'
                                    variant='paragraphSmall'
                                    colour='White'
                                >
                                    {t('confirm.form.resend.button')}
                                </Text>
                            </TextButton>
                        )}
                    </ResendCodeBox>
                </StyledButtonsContainer>
            </StyledForm>
        </div>
    );
};

export default ConfirmRegistration;
