import styled from '@emotion/styled';
import { redAdvance, white } from 'theme/colors';
import * as media from 'theme/custom-media';

export const Banner = styled.div`
    position: sticky;
    top: 0;
    background-color: ${redAdvance};
    padding: 0.9rem 8.8rem 1.4rem 1.3rem;
    z-index: 10;

    ${media.gteMediumMedia} {
        padding: 0.9rem 4.6rem;
        text-align: center;
    }
`;

export const Text = styled.p`
    font-size: 1.6rem;
    line-height: 1.68rem;
    color: ${white};
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 1.2rem;
    right: 0.6rem;
    width: 1.026rem;
    background-color: transparent;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: ${white};

    ${media.gteMediumMedia} {
        top: 1.3rem;
        right: 2.8rem;
    }
`;
