const appRoutes = {
    home: {
        root: '/',
        children: {
            productDetails: {
                root: '/product-details',
            },
            statements: {
                root: '/statements',
            },
            propertyValuation: {
                root: '/property-valuation',
            },
        },
    },
    auth: {
        root: ['/auth/*', '/recover'],
    },
    login: {
        root: '/auth/login',
    },
    register: {
        root: '/auth/register',
    },
    confirmRegistration: {
        root: '/auth/confirm-registration',
    },
    verifyCode: {
        root: '/auth/verify',
    },
    recover: {
        root: '/recover',
    },
    admin: {
        root: '/admin',
    },
    notFound: {
        root: '/*',
    },
};

export default appRoutes;
