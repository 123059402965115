import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Loading from 'components/loading/Loading';
import useAuth from 'helpers/useAuth';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { RecoverPasswordModel } from 'shared/auth/model/recover-password.model';
import appRoutes from 'utils/routes';

import {
    InputsContainer,
    StyledAlert,
    StyledButton,
    StyledForm,
    StyledLink,
    StyledParagraph,
} from '../../Authentication.styles';

import { schema } from './schema';

const Recover: FC = () => {
    const { t } = useTranslation('authentication');
    const history = useHistory();
    const { search } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm<RecoverPasswordModel>({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        mode: 'onChange',
    });
    const { authService } = useAuth();

    useEffect(() => {
        authService.cleanUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = useCallback(
        (data: RecoverPasswordModel) => {
            setIsLoading(true);
            setHasError(false);
            authService
                .requestPasswordRecovery(data)
                .then((result: boolean) => {
                    setIsLoading(false);
                    if (result) {
                        authService.recoverEmail = (
                            data as RecoverPasswordModel
                        ).email;
                        history.push(appRoutes.verifyCode.root + search);
                    } else {
                        setHasError(true);
                    }
                });
        },
        [authService, history, search],
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>): void => {
            // Ignore space key
            if (event.key === ' ') {
                event.preventDefault();
            }

            hasError && isValid && setHasError(false);
        },
        [hasError, isValid],
    );

    return (
        <div>
            <h1>{t('recover.title')}</h1>
            <StyledParagraph>{t('recover.sub-title')}</StyledParagraph>

            {hasError && (
                <StyledAlert severity='error'>
                    {t('recover.form.error')}
                </StyledAlert>
            )}

            <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
                <InputsContainer>
                    <TextField
                        {...register('email')}
                        id='email'
                        placeholder={t('recover.form.email')}
                        type='email'
                        error={!!errors.email}
                        helperText={
                            errors?.email?.type === 'email' &&
                            errors?.email?.message
                        }
                        fullWidth
                        onKeyDown={handleKeyDown}
                    />
                </InputsContainer>

                <StyledButton
                    variant='contained'
                    color='secondary'
                    type='submit'
                    fullWidth
                    disabled={isLoading || !isDirty || !isValid}
                >
                    {isLoading ? <Loading /> : t('recover.form.submit')}
                </StyledButton>
                <StyledLink to={appRoutes.login.root + search}>
                    {t('recover.form.login-return')}
                </StyledLink>
            </StyledForm>
        </div>
    );
};

export default Recover;
