import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import LockIcon from '@material-ui/icons/Lock';
import Text from 'components/atoms/text';
import LockAlt from 'components/icons/LockAlt';
import Loading from 'components/loading/Loading';
import useAuth from 'helpers/useAuth';
import useQueryParams from 'helpers/useQueryParams';
import mixpanel from 'mixpanel-browser';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { RegisterModel } from 'shared/auth/model/register.model';
import appRoutes from 'utils/routes';

import { todayDate } from '../../../../utils/dates';
import {
    InputsContainer,
    StyledAlert,
    StyledButton,
    StyledForm,
} from '../../Authentication.styles';

import {
    LoginLinkContainer,
    PasswordHintContainer,
    StyledEmailTextField,
    StyledInputAdornment,
    StyledLink,
    StyledList,
    StyledListItem,
} from './Register.styles';
import { schema } from './schema';

interface Error {
    generic?: boolean;
    alreadyExists?: boolean;
}

const hints = new Array(4).fill('hint').map((text, i) => text + '-' + (i + 1));

const Register: FC = () => {
    const { t } = useTranslation('authentication');
    const history = useHistory();
    const query = useQueryParams();
    const [isLoading, setIsLoading] = useState(false);
    const [emailParam, setEmailParam] = useState('');
    const [hasError, setHasError] = useState<Error>({});
    const {
        register,
        control,
        handleSubmit,
        setValue,
        trigger,
        watch,
        formState: { errors, isDirty, isValid },
    } = useForm<RegisterModel>({
        defaultValues: {
            email: '',
            marketing: false,
        },
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        mode: 'onChange',
    });
    const { authService } = useAuth();

    useEffect(() => {
        const email = query.get('emailAddress');

        if (email) {
            setEmailParam(email);
            setValue('email', email);
        }
    }, [query, setValue]);

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name === 'password') {
                trigger('confirmPassword');
            }
        });
        return () => subscription.unsubscribe();
    }, [trigger, watch]);

    const onSubmit = useCallback(
        (data: RegisterModel) => {
            setIsLoading(true);
            setHasError({});

            authService
                .register(data)
                .then((result: boolean) => {
                    setIsLoading(false);
                    authService.registeredUser = btoa(JSON.stringify(data));
                    if (result) {
                        mixpanel.identify(emailParam);
                        mixpanel.track('Sign up', {
                            email: emailParam,
                            created: todayDate(),
                        });
                        history.push(appRoutes.confirmRegistration.root);
                    } else {
                        setHasError({
                            alreadyExists: true,
                        });
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                    setHasError({
                        generic: true,
                    });
                });
        },
        [authService, emailParam, history],
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>): void => {
            // Ignore space key
            if (event.key === ' ') {
                event.preventDefault();
            }

            Object.keys(hasError).length && isValid && setHasError({});
        },
        [hasError, isValid],
    );

    return (
        <div>
            <Text as='h1' variant='headline3' weight='bold' colour='White'>
                {t('register.title')}
            </Text>

            <PasswordHintContainer>
                <div style={{ paddingRight: 4 }}>
                    <LockAlt />
                </div>
                <div>
                    <Text variant='paragraphSmall' colour='White'>
                        {t('register.password-hint.title')}
                    </Text>
                    <StyledList>
                        {hints.map((hint) => {
                            return (
                                <StyledListItem key={hint}>
                                    <Text
                                        variant='paragraphSmall'
                                        colour='White'
                                    >
                                        {t('register.password-hint.' + hint)}
                                    </Text>
                                </StyledListItem>
                            );
                        })}
                    </StyledList>
                </div>
            </PasswordHintContainer>

            {hasError?.generic && (
                <StyledAlert severity='error'>
                    {t('register.form.error.generic')}
                </StyledAlert>
            )}

            {hasError?.alreadyExists && (
                <StyledAlert severity='error'>
                    {t('register.form.error.already-exists.start')}
                    <Link to={appRoutes.login.root}>
                        {t('register.form.error.already-exists.end')}
                    </Link>
                </StyledAlert>
            )}

            <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
                <InputsContainer>
                    <Controller
                        control={control}
                        name='email'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <StyledEmailTextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                inputRef={ref}
                                placeholder={t('register.form.email')}
                                type='email'
                                error={!!errors.email}
                                InputProps={{
                                    startAdornment: (
                                        <>
                                            {!emailParam && (
                                                <StyledInputAdornment position='start'>
                                                    <LockIcon fontSize='small' />
                                                </StyledInputAdornment>
                                            )}
                                        </>
                                    ),
                                }}
                                helperText={
                                    errors?.email?.type === 'email' &&
                                    errors?.email?.message
                                }
                                fullWidth
                                disabled
                                onKeyDown={handleKeyDown}
                                noSpacing={!emailParam}
                            />
                        )}
                    />
                    <TextField
                        {...register('password')}
                        id='password'
                        placeholder={t('register.form.password')}
                        type='password'
                        error={!!errors.password}
                        helperText={
                            errors?.password?.type === 'matches' &&
                            errors?.password?.message
                        }
                        fullWidth
                        onKeyDown={handleKeyDown}
                    />
                    <TextField
                        {...register('confirmPassword')}
                        id='confirmPassword'
                        placeholder={t('register.form.confirm-password')}
                        type='password'
                        error={!!errors.confirmPassword}
                        helperText={
                            errors?.confirmPassword?.type === 'oneOf' &&
                            errors?.confirmPassword?.message
                        }
                        fullWidth
                        onKeyDown={handleKeyDown}
                    />
                </InputsContainer>
                {/*<div>*/}
                {/*    <Trans i18nKey="register.form.marketing.label">*/}
                {/*        Would you like to receive the occasional marketing*/}
                {/*        communication from us? You can unsubscribe at any time*/}
                {/*        by emailing{' '}*/}
                {/*        <a href="mailto:hello@selinaadvance.com?subject=Unsubscribe">*/}
                {/*            hello@selinaadvance.com*/}
                {/*        </a>*/}
                {/*        .*/}
                {/*    </Trans>*/}
                {/*</div>*/}
                {/*<Controller*/}
                {/*    name="marketing"*/}
                {/*    render={({ field: { onChange, value, ref } }) => (*/}
                {/*        <FormControlLabel*/}
                {/*            control={*/}
                {/*                <Checkbox*/}
                {/*                    onChange={onChange}*/}
                {/*                    checked={value}*/}
                {/*                    inputRef={ref}*/}
                {/*                    color="secondary"*/}
                {/*                />*/}
                {/*            }*/}
                {/*            label={t('register.form.marketing.option')}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*    control={control}*/}
                {/*/>*/}
                <Divider />
                <div>
                    <Trans i18nKey='register.form.marketing.label'>
                        By pressing 'Get Started', you verify that you have read
                        agree with Selina Finance's{' '}
                        <StyledLink
                            target='blank'
                            to={{
                                pathname:
                                    'https://www.selinafinance.co.uk/terms-of-use',
                            }}
                        >
                            Terms and Conditions
                        </StyledLink>{' '}
                        and{' '}
                        <StyledLink
                            target='blank'
                            to={{
                                pathname:
                                    'https://www.selinafinance.co.uk/privacy-policy',
                            }}
                        >
                            Privacy Policy
                        </StyledLink>
                        .
                    </Trans>
                </div>
                <StyledButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    fullWidth
                    disabled={isLoading || !isDirty || !isValid}
                >
                    {isLoading ? <Loading /> : t('register.form.sign-up')}
                </StyledButton>
            </StyledForm>

            <LoginLinkContainer>
                <span>{t('register.sub-title')}</span>{' '}
                <StyledLink to={appRoutes.login.root}>
                    {t('register.sign-in')}
                </StyledLink>
            </LoginLinkContainer>
        </div>
    );
};

export default Register;
