import styled from '@emotion/styled';
import { TextField as MuiTextField } from '@material-ui/core';
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { Control, Controller, FieldPath } from 'react-hook-form';
import colours from 'theme/colours/v2';

interface TextFieldProps<T extends Record<string, any>> {
    name: FieldPath<T>;
    control: Control<T>;
    InputProps?: MuiTextFieldProps;
}

const StyledInput = styled(MuiTextField)`
    background-color: ${colours.White};

    .MuiOutlinedInput-input {
        padding: 1.4rem 1.6rem;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: ${colours.Steel100};
    }
`;

const TextField = <T extends Record<string, any>>({
    name,
    control,
    InputProps,
}: TextFieldProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                return (
                    <StyledInput
                        {...field}
                        variant='outlined'
                        type='text'
                        error={!!fieldState.error}
                        fullWidth
                        {...InputProps}
                    />
                );
            }}
        />
    );
};

export default TextField;
