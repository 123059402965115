import styled from '@emotion/styled';
import { oldRose } from 'theme/colors';
import * as media from 'theme/custom-media';

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 3.65rem;

    & > button {
        flex: 1;

        &:not(:last-of-type) {
            margin-right: 1rem;
        }
    }

    ${media.gteMediumMedia} {
        margin: 0;
        border-radius: var(--global-border-radius);
        overflow: hidden;

        & .MuiButton-root {
            font-size: 1.6rem;
            line-height: 2rem;
            height: 3.4rem;
        }

        & > button {
            border-radius: 0;

            &:not(:last-of-type) {
                margin: 0;
                border-right: 0.1rem solid ${oldRose};
            }
        }
    }
`;
