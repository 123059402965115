import styled from '@emotion/styled';
import { greenSuccess, grey150, grey500, grey950, white } from 'theme/colors';
import * as media from 'theme/custom-media';

interface PaymentListProps {
    show?: boolean;
}

interface PaymentAmountProps {
    isGreen?: boolean;
}

export const PaymentContainer = styled.section`
    margin: 0 1.6rem 3rem;
    color: ${grey950};

    ${media.gteMediumMedia} {
        padding: 1.8rem 0 0;
        margin: 0;
        background-color: ${white};
        border-radius: 0 0 0.3rem 0.3rem;
        overflow: hidden;
    }
`;

export const Title = styled.div`
    font-size: 1.8rem;
    line-height: 2.52rem;
    margin-bottom: 2rem;
`;

export const PaymentList = styled.div<PaymentListProps>`
    display: flex;
    flex-direction: column;

    ${media.gteMediumMedia} {
        position: relative;
        display: block;
        max-height: ${(props) => (props.show ? '52.1rem' : 'unset')};
        overflow: hidden;
    }
`;

export const PaymentItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem 2.1rem 1.8rem 1.6rem;
    background-color: ${white};
    border-radius: var(--global-border-radius);
    margin-bottom: 1rem;

    ${media.gteMediumMedia} {
        height: 7rem;
        padding: 0 3.2rem;
        margin: 0;
    }
`;

export const PaymentDate = styled.div`
    display: flex;
    flex-direction: column;

    & p {
        font-size: 1.6rem;
        line-height: 2rem;
        text-transform: capitalize;

        &:last-of-type {
            font-size: 1.2rem;
            line-height: 1.56rem;
        }
    }

    ${media.gteMediumMedia} {
        & p:last-of-type {
            font-size: 1.4rem;
            line-height: 1.82rem;
            color: ${grey500};
            margin-top: 0.4rem;
        }
    }
`;

export const PaymentAmount = styled.div<PaymentAmountProps>`
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 493;
    color: ${(props) => (props.isGreen ? greenSuccess : 'inherit')};
`;

export const Shadow = styled.div`
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0.84) 100%
    );
    height: 41px;
    position: absolute;
    width: 100%;
    bottom: 0;
`;

export const ShowToggle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.6rem;
    font-size: 1.4rem;
    line-height: 1.82rem;
    border-top: 0.1rem solid ${grey150};
    cursor: pointer;
`;
