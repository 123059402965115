import { FC, useMemo, useState } from 'react';

import useFacility from 'helpers/useFacility';
import useViewport from 'helpers/useViewport';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { getDayMonthAndYear } from 'utils/dates';
import { GBPFormatter } from 'utils/formatNumber';

import {
    PaymentAmount,
    PaymentContainer,
    PaymentDate,
    PaymentItem,
    PaymentList,
    Shadow,
    ShowToggle,
    Title,
} from './RepaymentsWithdrawals.styles';

const RepaymentsWithdrawals: FC = () => {
    const { gteMedium: isDesktop } = useViewport();
    const [show, setShow] = useState(true);
    const { t } = useTranslation('repaymentsWithdrawals');
    const { statements } = useFacility();
    const isStatementsAboveMax = useMemo(
        () => statements?.length > 7,
        [statements?.length],
    );
    const sortedStatements = useMemo(
        () => sortBy(statements, (el) => el.date || el.datetime)?.reverse(),
        [statements],
    );

    const handleClick = (): void => {
        setShow(!show);
    };

    return (
        <PaymentContainer>
            {!isDesktop && <Title>{t('title')}</Title>}
            <PaymentList show={show}>
                {sortedStatements?.length &&
                    sortedStatements.map((value: any, index: number) => {
                        const type = value?.type;
                        const isContractualPayment = type
                            ?.toLowerCase()
                            ?.includes('contractual payment');

                        return (
                            <PaymentItem key={index}>
                                <PaymentDate>
                                    <p>
                                        {getDayMonthAndYear(
                                            value?.date || value?.datetime,
                                        )}
                                    </p>
                                    <p>{value?.type}</p>
                                </PaymentDate>
                                <PaymentAmount isGreen={isContractualPayment}>
                                    {GBPFormatter(value?.amount, 2)}
                                </PaymentAmount>
                            </PaymentItem>
                        );
                    })}
                {show && isDesktop && isStatementsAboveMax && <Shadow />}
            </PaymentList>
            {isDesktop && isStatementsAboveMax && (
                <ShowToggle onClick={handleClick}>
                    {show ? t('see-all') : t('see-less')}
                </ShowToggle>
            )}
        </PaymentContainer>
    );
};

export default RepaymentsWithdrawals;
