const purposes = [
    'Debt consolidation',
    'Home refurbishment',
    'Holiday',
    'Medical bills',
    'Property - second home purchase',
    'Property - buy-to-let purchase',
    'School fees',
    'Tax bill',
    'Vehicle purchase',
    'Wedding',
    'Other',
    'Business purposes',
];

export default purposes;
