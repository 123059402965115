import { FC } from 'react';

import useFacility from 'helpers/useFacility';
import { useTranslation } from 'react-i18next';

import {
    Heading,
    InfoBox,
    InfoData,
    InfoRow,
    InfoTitle,
    Statement,
} from './Statements.styles';

interface Props {
    title?: string;
}

const StatementDetails: FC<Props> = ({ title }) => {
    const { t } = useTranslation('statement');
    const { termInYears, remainingPayments } = useFacility();

    return (
        <Statement>
            {title && <Heading>{title}</Heading>}
            <InfoRow>
                <InfoBox>
                    <InfoTitle>{t('term')}</InfoTitle>
                    <InfoData>
                        {termInYears}
                        <span> {t('years', { count: termInYears })}</span>
                    </InfoData>
                </InfoBox>
                <InfoBox>
                    <InfoTitle>{t('remaining-payments')}</InfoTitle>
                    <InfoData>{remainingPayments}</InfoData>
                </InfoBox>
            </InfoRow>
        </Statement>
    );
};

export default StatementDetails;
