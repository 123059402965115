import { FC } from 'react';

import CircularProgressbar from 'components/circular-progressbar/CircularProgressbar';
import WelcomeMessage from 'components/welcome-message/WelcomeMessage';
import useFacility from 'helpers/useFacility';
import { useTranslation } from 'react-i18next';
import { getDayAndMonth, isBeforeToday } from 'utils/dates';
import { GBPFormatter } from 'utils/formatNumber';

import {
    BalanceInfo,
    BalanceSection,
    CardIcon,
    CircularContainer,
    Container,
    HighlightedNumber,
    HighlightedText,
    IconContainer,
    InfoBox,
    InfoData,
    InfoRow,
    InfoTitle,
    LockIcon,
    Statement,
    WelcomeContent,
} from './Main.styles';

const Main: FC = () => {
    const { t } = useTranslation(['dashboard', 'statement']);
    const {
        usedFund,
        creditLimit,
        availableFund,
        owePercentage,
        nextPaymentDue,
        interestRate,
        nextPaymentAmount,
        pendingPercentage,
    } = useFacility();

    return (
        <Container>
            <WelcomeContent>
                <WelcomeMessage />
            </WelcomeContent>
            <CircularContainer>
                <CircularProgressbar
                    value={owePercentage}
                    stroke={14}
                    pendingPercentage={pendingPercentage}
                >
                    <HighlightedNumber>
                        {GBPFormatter(usedFund)}
                    </HighlightedNumber>
                    <HighlightedText>{t('owe-amount')}</HighlightedText>
                </CircularProgressbar>
            </CircularContainer>
            <BalanceSection>
                <BalanceInfo>
                    <IconContainer>
                        <CardIcon />
                    </IconContainer>
                    <p>{t('available-funds')}</p>
                    <p>{GBPFormatter(availableFund)}</p>
                </BalanceInfo>
                <BalanceInfo>
                    <IconContainer>
                        <LockIcon />
                    </IconContainer>
                    <p>{t('credit-limit')}</p>
                    <p>{GBPFormatter(creditLimit)}</p>
                </BalanceInfo>
            </BalanceSection>
            <Statement>
                <InfoRow>
                    <InfoBox>
                        <InfoTitle>{t('statement:next-payment-due')}</InfoTitle>
                        <InfoData>
                            {nextPaymentDue && !isBeforeToday(nextPaymentDue)
                                ? getDayAndMonth(nextPaymentDue)
                                : t('statement:not-applicable')}
                        </InfoData>
                    </InfoBox>
                    <InfoBox>
                        <InfoTitle>{t('statement:interest-rate')}</InfoTitle>
                        <InfoData>{`${interestRate}%`}</InfoData>
                    </InfoBox>
                </InfoRow>
                <InfoRow>
                    <InfoBox>
                        <InfoTitle>{t('statement:repayment-amount')}</InfoTitle>
                        <InfoData>{GBPFormatter(nextPaymentAmount)}</InfoData>
                    </InfoBox>
                </InfoRow>
            </Statement>
        </Container>
    );
};

export default Main;
