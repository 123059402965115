import { FC, Fragment, createRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import MuiModal from '@material-ui/core/Modal';
import useViewport from 'helpers/useViewport';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import useProfile from '../../helpers/useProfile';
import useProfileByServicingId from '../../helpers/useProfileByServicingId';
import { APPLICATION_TYPE } from '../../services/profile/profile';

import { ButtonContent } from './Modal.styles';
import EnterAmount from './enter-amount-form/EnterAmount';
import Repayment from './repayment/Repayment';

const Modal: FC = () => {
    const { t } = useTranslation('modal');
    const { gteMedium: isDesktop } = useViewport();
    const refModal = createRef<HTMLDivElement>();
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const profile = useProfile();
    const profileByServicingID = useProfileByServicingId();

    const applicationType =
        profile.applicationType || profileByServicingID.applicationType;

    const handleOpenModal = (type: string) => {
        const mixTrackType =
            type === 'withdrawal' ? 'Drawdown Intended' : 'Repayment Intended';
        mixpanel.track(mixTrackType);
        modalType !== type && setModalType(type);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <Fragment>
            <ButtonContent>
                {applicationType === APPLICATION_TYPE.HELOC && (
                    <Button
                        onClick={() => handleOpenModal('withdrawal')}
                        variant='contained'
                        color='primary'
                        type='button'
                    >
                        {t('button.withdrawal')}
                    </Button>
                )}
                <Button
                    onClick={() => handleOpenModal('repayment')}
                    variant={isDesktop ? 'contained' : 'outlined'}
                    color={isDesktop ? 'primary' : 'secondary'}
                    type='button'
                >
                    {t('button.repayment')}
                </Button>
            </ButtonContent>
            <MuiModal
                open={openModal}
                onClose={handleCloseModal}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(50, 51, 51, 0.5)',
                    },
                }}
            >
                {modalType === 'repayment' ? (
                    <Repayment ref={refModal} onCloseModal={handleCloseModal} />
                ) : (
                    <EnterAmount
                        ref={refModal}
                        onCloseModal={handleCloseModal}
                    />
                )}
            </MuiModal>
        </Fragment>
    );
};

export default Modal;
