import styled from '@emotion/styled';
import { grey700, grey900, grey950, white } from 'theme/colors';

export const CircularContainer = styled.section`
    max-width: 40rem;
    line-height: 0;
    margin: 0.8rem auto 0;
    padding: 0 4.4rem;

    & p:last-child {
        margin-bottom: 0;
    }
`;

export const HighlightedText = styled.p`
    font-size: 1.229rem;
    line-height: 1.597rem;
    color: ${grey700};
`;

export const HighlightedNumber = styled.p`
    font-size: 2.984rem;
    line-height: 4.476rem;
    color: ${grey950};
    margin-bottom: 1.317rem;
`;

export const StyledText = styled.p`
    font-size: 1.404rem;
    line-height: 1.755rem;
    color: ${grey950};
    margin-bottom: 0.878rem;
`;

export const BottomSection = styled.section`
    padding: 1.5rem 2rem;
`;

export const Statement = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InfoRow = styled.div`
    display: flex;

    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }
`;

export const InfoBox = styled.div`
    background-color: ${white};
    flex: 1;
    padding: 1.5rem;
    color: ${grey900};
    overflow: hidden;

    &:not(:last-of-type) {
        margin-right: 1rem;
    }
`;

export const InfoTitle = styled.p`
    font-size: 1.4rem;
    line-height: 1.82rem;
    margin-bottom: 0.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const InfoData = styled.p`
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 493;
`;
