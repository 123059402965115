import styled from '@emotion/styled';
import { grey200, white } from 'theme/colors';

export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 3.2rem;
    height: 3.2rem;
    border: 0.1rem solid ${grey200};
    background-color: ${white};
    border-radius: 50%;
    cursor: pointer;

    & > svg {
        width: 1.283rem;
    }
`;
