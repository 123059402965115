import { FUNDING_PROVIDER } from '../services/profile/profile';

export const LEGACY_ACCOUNT_NUMBER = '50039772';
export const WAM_ACCOUNT_NUMBER = '50039756';
export const VANQUIS_ACCOUNT_NUMBER = '60039713';

export const SELINA_SORT_CODE = '40-11-77';

export const accountNumbers = {
    [FUNDING_PROVIDER.VANQUIS]: VANQUIS_ACCOUNT_NUMBER,
    [FUNDING_PROVIDER.WAM]: WAM_ACCOUNT_NUMBER,
    [FUNDING_PROVIDER.CANTERBURY_FUNDING_LIMITED]: WAM_ACCOUNT_NUMBER,
    legacy: LEGACY_ACCOUNT_NUMBER,
};
