export const GBPFormatter = (
    number: number = 0,
    fractionDigits: number = 0,
): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(number || 0);
};
