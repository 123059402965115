import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import colours from 'theme/colours/v2';
import { gteExtraSmallMedia } from 'theme/custom-media';

export const Form = styled.form({
    width: '100%',
    // display: 'flex',
    // justifyContent: 'center',
});

export const SeachInputWrapper = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    max-width: 475px;
    min-width: 355px;
    flex-direction: column;
    margin: 0 auto;

    ${gteExtraSmallMedia} {
        flex-direction: row;
        align-items: center;
    }
`;

export const ResultsWrapper = styled.div({
    paddingTop: 18,
    width: '100%',
    maxWidth: 478,
    minWidth: 355,
});

export const ResultsCard = styled.div`
    background-color: ${colours.White};
    padding: 16px 10px;
    border-top: solid 2px ${colours.Steel100};
    margin-top: 8px;
    display: flex;
    align-items: center;
    max-width: 475px;
    width: 100%;

    p + p {
        padding-top: 12px;
    }
`;

export const RightColumn = styled.div({
    marginLeft: 'auto',
});

export const TextButton = styled(Link)({
    display: 'block',
    padding: '12px 20px',
});

export const Title = styled.div({
    paddingBottom: 4,
});

export const SpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    min-height: 70px;
    justify-content: center;
    align-items: center;
`;
