import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'state/buildStore';

export interface PropertyResponse {
    suggestions: {};
    valuations: [];
}

export interface PropertyArgs {
    applicationId: string;
    body: object;
}

export const api = createApi({
    reducerPath: 'propertyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: window._env_.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.authToken?.[0];
            const idToken = (getState() as RootState)?.auth?.authToken?.[2];

            if (token) {
                headers.set('authorization', `Bearer ${token}`);
                headers.set('x-user-profile', `Bearer ${idToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getProperty: builder.query<PropertyResponse, string>({
            query: (applicationId: string) => `property/${applicationId}`,
        }),
        postProperty: builder.mutation<PropertyResponse, PropertyArgs>({
            query: ({ applicationId, body }) => ({
                url: `property/${applicationId}`,
                method: 'POST',
                body: body,
            }),
        }),
    }),
});

export const { useGetPropertyQuery, usePostPropertyMutation } = api;
