import dashboard from './components/dashboard.json';
import disclaimer from './components/disclaimer.json';
import error from './components/error.json';
import header from './components/header.json';
import logout from './components/logout.json';
import modal from './components/modal.json';
import nav from './components/nav.json';
import nextInstallment from './components/next-installment.json';
import propertyValuation from './components/property-valuation.json';
import repaymentBalance from './components/repayment-balance.json';
import repaymentsWithdrawals from './components/repayments-withdrawals.json';
import statement from './components/statement.json';
import support from './components/support.json';
import welcome from './components/welcome.json';
import admin from './pages/admin.json';
import authentication from './pages/authentication.json';
import home from './pages/home.json';

const en = {
    authentication,
    dashboard,
    disclaimer,
    error,
    header,
    home,
    admin,
    logout,
    modal,
    nav,
    nextInstallment,
    propertyValuation,
    repaymentBalance,
    repaymentsWithdrawals,
    statement,
    support,
    welcome,
};

export default en;
