/* eslint-disable max-len */
import { SVGProps } from 'react';

const EmailEnvelope = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={81}
        height={73}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='m47.308 53.572 23.309 18.763c-.319.112-.661.173-1.018.173H11.4c-.357 0-.7-.061-1.018-.173l23.308-18.763 3.637 3.244a4.765 4.765 0 0 0 6.343 0l3.637-3.244ZM8.47 31.079l23.485 20.944L8.646 70.787a3.044 3.044 0 0 1-.308-1.338V31.97c0-.31.046-.609.132-.89Zm64.06 0c.086.283.132.582.132.891V69.45c0 .48-.111.934-.308 1.338l-23.31-18.764L72.53 31.08Z'
            fill='#E3DDD1'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M43.862 2.425a4.765 4.765 0 0 0-6.724 0L32.815 6.73h15.37l-4.323-4.305Zm18.08 18.002V36.42l8.336-7.434-.086-.018.173-.154-8.424-8.387ZM19.058 36.42V20.427l-8.424 8.387.174.155-.087.017 8.337 7.435Z'
            fill='#C3999B'
        />
        <path
            d='M21.239 7.495h38.522V36.56h2.18V5.2H19.059v31.36h2.18V7.495Z'
            fill='#F4F1ED'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M47.304 16.632c-1.96-.992-4.188-1.489-6.68-1.489-2.585 0-4.871.523-6.86 1.569-1.989 1.046-3.533 2.486-4.632 4.32-1.1 1.834-1.65 3.903-1.65 6.207 0 2.34.545 4.431 1.636 6.274 1.09 1.844 2.62 3.288 4.591 4.334 1.97 1.045 4.239 1.568 6.805 1.568 1.063 0 2.14-.106 3.23-.319 1.09-.213 2.04-.523 2.846-.93l-.743-2.127a11.3 11.3 0 0 1-2.598.877c-.925.195-1.837.293-2.735.293-2.072 0-3.904-.421-5.499-1.263-1.595-.842-2.827-2.016-3.698-3.523-.87-1.506-1.306-3.234-1.306-5.184 0-1.914.44-3.624 1.32-5.13.88-1.507 2.126-2.681 3.74-3.523 1.612-.842 3.463-1.262 5.553-1.262 2.07 0 3.904.398 5.498 1.196 1.595.797 2.837 1.914 3.726 3.35.888 1.435 1.333 3.092 1.333 4.97 0 1.56-.197 2.752-.591 3.576-.394.825-.967 1.236-1.719 1.236-.421 0-.765-.141-1.03-.425-.266-.284-.4-.744-.4-1.382v-9.039h-3.05v1.728a5.085 5.085 0 0 0-1.967-1.409c-.779-.319-1.654-.479-2.625-.479-1.246 0-2.374.288-3.382.864a6.158 6.158 0 0 0-2.364 2.393c-.568 1.02-.852 2.176-.852 3.47 0 1.31.284 2.48.852 3.509a6.134 6.134 0 0 0 2.364 2.405c1.008.576 2.136.864 3.382.864 2.2 0 3.849-.77 4.949-2.313.238.745.66 1.316 1.264 1.715.605.4 1.347.599 2.227.599 1.65 0 2.942-.652 3.877-1.955.934-1.302 1.402-3.106 1.402-5.41 0-2.233-.546-4.235-1.636-6.008-1.09-1.772-2.616-3.154-4.578-4.147ZM37.243 30.426c.72.792 1.662 1.188 2.823 1.188 1.143 0 2.08-.392 2.81-1.174.73-.782 1.095-1.845 1.095-3.188 0-1.325-.365-2.374-1.095-3.147-.73-.773-1.666-1.16-2.81-1.16-1.161 0-2.102.391-2.823 1.173-.722.783-1.082 1.827-1.082 3.134 0 1.325.36 2.383 1.082 3.174Z'
            fill='#F4F1ED'
        />
    </svg>
);

export default EmailEnvelope;
