import styled from '@emotion/styled';
import {
    grey300,
    grey600,
    grey700,
    grey900,
    grey950,
    white,
} from 'theme/colors';
import * as media from 'theme/custom-media';

export const ModalContent = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 5.5rem 1.6rem 2.4rem;
    background-color: ${white};
    outline: none;
    text-align: center;
    color: ${grey950};
    overflow: auto;

    ${media.lteSmallMedia} {
        & .MuiInput-underline {
            &:before,
            &:after,
            &:hover {
                border: none !important;
            }
        }
    }

    .MuiInputBase-input::-webkit-input-placeholder {
        opacity: 1;
    }

    & form {
        flex-grow: 1;
        position: relative;

        & button {
            ${media.lteSmallMedia} {
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }

    & label {
        font-size: 2.4rem;
        line-height: 2.88rem;
        text-align: center;
        color: ${grey950};
    }

    & input {
        font-size: 4rem;
        line-height: 4rem;
        text-align: center;
        color: ${grey950};
    }

    ${media.gteMediumMedia} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: 39.6rem;
        padding: 4.4rem 4.8rem 4.8rem;
        border-radius: 0.6rem;

        & label {
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: initial;
            margin-bottom: 1.2rem;
        }

        & input {
            font-size: 1.8rem;
            line-height: 2.7rem;
            text-align: initial;
        }

        .MuiInputBase-root {
            height: 4.8rem;
            border-radius: 0.2rem;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: ${grey900} !important;
        }

        .MuiInputBase-input::-webkit-input-placeholder {
            color: ${grey300};
        }
    }
`;

export const ArrowButton = styled.button`
    position: absolute;
    width: 2.533rem;
    background-color: transparent;
    padding: 0;
    margin: 0.633rem 0 0 0.333rem;
    border: 0;
    cursor: pointer;
    color: ${grey600};
`;

export const CircularContainer = styled.section`
    max-width: 40rem;
    line-height: 0;
    margin: 2.9rem auto 2.4rem;
    padding: 0 4.2rem;

    ${media.gteMediumMedia} {
        margin: 2.4rem auto 3.2rem;
        padding: 0 3.8rem;
    }
`;

export const AvailableFunds = styled.div`
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${grey700};

    ${media.gteMediumMedia} {
        font-size: 1.4rem;
        line-height: 1.82rem;
        margin: 1rem 0 2.4rem;
        text-align: initial;
    }
`;

export const Title = styled.p`
    font-size: 2.8rem;
    line-height: 3.36rem;
`;

export const Amount = styled.div`
    font-size: 3rem;
    line-height: 3rem;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    width: 1.026rem;
    background-color: transparent;
    padding: 0;
    margin: 2.487rem 2.487rem 0 0;
    border: 0;
    cursor: pointer;
    color: ${grey600};
`;

export const Disclaimer = styled.div`
    text-align: left;
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${grey700};
    margin: 2.4rem 0;

    ${media.gteMediumMedia} {
        margin-bottom: 0;
    }
`;
