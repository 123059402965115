import styled from '@emotion/styled';
import { grey150, grey900, grey950, white } from 'theme/colors';
import * as media from 'theme/custom-media';

export const Statement = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 1.6rem 2.4rem;

    ${media.gteMediumMedia} {
        margin: 2rem 3.2rem 0;
    }
`;

export const Heading = styled.p`
    font-size: 1.8rem;
    line-height: 2.52rem;
    color: ${grey950};
    margin: 0 0 2rem;
`;

export const InfoRow = styled.div`
    display: flex;

    &:not(:last-of-type) {
        margin-bottom: 2.1rem;
    }
`;

export const InfoBox = styled.div`
    background-color: ${white};
    border-radius: var(--global-border-radius);
    flex: 1;
    padding: 1.8rem 2rem 1.4rem;
    color: ${grey900};
    overflow: hidden;

    &:not(:last-of-type) {
        margin-right: 1.6rem;
    }

    ${media.gteMediumMedia} {
        border: 0.1rem solid ${grey150};
        padding-right: 0;
    }
`;

export const InfoTitle = styled.p`
    font-size: 1.4rem;
    line-height: 1.82rem;
    margin-bottom: 0.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const InfoData = styled.p`
    font-size: 2.4rem;
    line-height: 3.6rem;

    ${media.gteMediumMedia} {
        font-size: 2rem;
    }
`;
