import { FC, Fragment } from 'react';

import RepaymentBalance from 'components/repayment-balance/RepaymentBalance';
import RepaymentsWithdrawals from 'components/repayments-withdrawals/RepaymentsWithdrawals';

const Statements: FC = () => {
    return (
        <Fragment>
            <RepaymentBalance />
            <RepaymentsWithdrawals />
        </Fragment>
    );
};

export default Statements;
