import styled from '@emotion/styled';
import { SquaredSelinaBackground } from 'components/icons';

export const BackgroundContainer = styled.div`
    position: relative;
    display: flex;
`;

export const BackgroundImage = styled(SquaredSelinaBackground)`
    position: absolute;
    border-radius: 50%;
`;

export const CircularContainer = styled.div`
    position: absolute;
    width: 100%;
`;
