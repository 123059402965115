/* eslint-disable max-len */
import React from 'react';

const OnlineForm: React.FC = (props) => (
    <svg viewBox='0 0 25 24' fill='none' {...props}>
        <g clipPath='url(#clip0)'>
            <path
                d='M4.83325 11.0005C7.31875 11.0005 9.33325 8.98599 9.33325 6.50049C9.33325 4.01499 7.31875 2.00049 4.83325 2.00049C2.34775 2.00049 0.333252 4.01499 0.333252 6.50049C0.333252 8.98599 2.34775 11.0005 4.83325 11.0005ZM4.83325 2.37549C7.108 2.37549 8.95825 4.22574 8.95825 6.50049C8.95825 8.77524 7.108 10.6255 4.83325 10.6255C2.5585 10.6255 0.708252 8.77524 0.708252 6.50049C0.708252 4.22574 2.5585 2.37549 4.83325 2.37549Z'
                fill='currentColor'
            />
            <path
                d='M4.83325 22C7.31875 22 9.33325 19.9855 9.33325 17.5C9.33325 15.0145 7.31875 13 4.83325 13C2.34775 13 0.333252 15.0145 0.333252 17.5C0.333252 19.9855 2.34775 22 4.83325 22ZM4.83325 13.375C7.108 13.375 8.95825 15.2253 8.95825 17.5C8.95825 19.7747 7.108 21.625 4.83325 21.625C2.5585 21.625 0.708252 19.7747 0.708252 17.5C0.708252 15.2253 2.5585 13.375 4.83325 13.375Z'
                fill='currentColor'
            />
            <path
                d='M21.3333 4.29004H12.3333V4.66504H21.3333V4.29004Z'
                fill='currentColor'
            />
            <path
                d='M24.3331 7.3125H12.3279V7.6875H24.3331V7.3125Z'
                fill='currentColor'
            />
            <path
                d='M21.3333 15.3008H12.3333V15.6758H21.3333V15.3008Z'
                fill='currentColor'
            />
            <path
                d='M24.3331 18.3242H12.3279V18.6992H24.3331V18.3242Z'
                fill='currentColor'
            />
            <path
                d='M6.78321 15.9459L6.50571 15.6924L3.87396 18.5626L2.80896 17.4976L2.54346 17.7631L3.88521 19.1049L6.78321 15.9459Z'
                fill='currentColor'
            />
            <path
                d='M6.78321 4.99911L6.50571 4.74561L3.87396 7.61586L2.80896 6.55011L2.54346 6.81561L3.88521 8.1581L6.78321 4.99911Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0'>
                <rect
                    width='24'
                    height='24'
                    fill='white'
                    transform='translate(0.333252)'
                />
            </clipPath>
        </defs>
    </svg>
);

export default OnlineForm;
