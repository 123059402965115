/* eslint-disable max-len */
import React from 'react';

const ArrowLeft: React.FC = (props) => (
    <svg viewBox='0 0 40 40' fill='none' {...props}>
        <path
            d='M20 16.25V22.5'
            stroke='currentColor'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M17.8442 6.25L4.09422 30C3.87517 30.3794 3.75967 30.8097 3.75928 31.2478C3.75889 31.6859 3.87363 32.1164 4.09201 32.4962C4.31039 32.876 4.62474 33.1917 5.00356 33.4118C5.38238 33.6318 5.81238 33.7485 6.25047 33.75H33.7505C34.1886 33.7485 34.6186 33.6318 34.9974 33.4118C35.3762 33.1917 35.6906 32.876 35.9089 32.4962C36.1273 32.1164 36.2421 31.6859 36.2417 31.2478C36.2413 30.8097 36.1258 30.3794 35.9067 30L22.1567 6.25C21.9393 5.87011 21.6254 5.55439 21.2468 5.33482C20.8681 5.11525 20.4382 4.99961 20.0005 4.99961C19.5628 4.99961 19.1328 5.11525 18.7542 5.33482C18.3755 5.55439 18.0616 5.87011 17.8442 6.25V6.25Z'
            stroke='currentColor'
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M20 30C21.0355 30 21.875 29.1605 21.875 28.125C21.875 27.0895 21.0355 26.25 20 26.25C18.9645 26.25 18.125 27.0895 18.125 28.125C18.125 29.1605 18.9645 30 20 30Z'
            fill='currentColor'
        />
    </svg>
);

export default ArrowLeft;
