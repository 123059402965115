import { css } from '@emotion/react';

export default css`
    :root {
        /* Nav height */
        --mobile-nav-height: 10rem;

        /* Header height */
        --desktop-header-height: 6.8rem;

        --global-border-radius: 0.2rem;

        --global-letter-spacing: 0.07em;
    }
`;
