import { FC } from 'react';

import StatementDetails from 'components/statements/StatementDetails';
import StatementInfo from 'components/statements/StatementInfo';
import { useTranslation } from 'react-i18next';

import { Container, Title } from './ProductDetails.styles';

const ProductDetails: FC = () => {
    const { t } = useTranslation('dashboard');

    return (
        <Container>
            <Title>{t('product-details')}</Title>
            <StatementInfo />
            <StatementDetails />
        </Container>
    );
};

export default ProductDetails;
