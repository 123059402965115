import { css } from '@emotion/react';
// @ts-ignore
import font from 'media/fonts/NeueSingularD/Neue-Singular-D-Regular.otf';

import { family, weight } from './fonts';

export default css`
    /**
      * Neue Singular D Regular
    */
    @font-face {
        font-family: ${family.neueSingular.standard};
        font-weight: ${weight.regular};
        font-style: normal;
        font-display: swap;
        src: url('${font}') format('opentype');
    }
`;
