import { FC } from 'react';

import useFacility from 'helpers/useFacility';
import { useTranslation } from 'react-i18next';
import { isBeforeToday } from 'utils/dates';
import { GBPFormatter } from 'utils/formatNumber';

import {
    Heading,
    InfoBox,
    InfoData,
    InfoRow,
    InfoTitle,
    Statement,
} from './Statements.styles';

interface Props {
    title?: string;
}

const StatementInfo: FC<Props> = ({ title }) => {
    const { t } = useTranslation('statement');
    const {
        nextPaymentDueInDays,
        interestRate,
        nextPaymentAmount,
        nextPaymentDue,
    } = useFacility();

    return (
        <Statement>
            {title && <Heading>{title}</Heading>}
            <InfoRow>
                <InfoBox>
                    <InfoTitle>{t('next-payment-due')}</InfoTitle>
                    <InfoData>
                        {nextPaymentDue && !isBeforeToday(nextPaymentDue)
                            ? `${nextPaymentDueInDays} ${t('days', {
                                  count: nextPaymentDueInDays,
                              })}`
                            : t('not-applicable')}
                    </InfoData>
                </InfoBox>
                <InfoBox>
                    <InfoTitle>{t('interest-rate')}</InfoTitle>
                    <InfoData>{`${interestRate} %`}</InfoData>
                </InfoBox>
            </InfoRow>
            <InfoRow>
                <InfoBox>
                    <InfoTitle>{t('repayment-amount')}</InfoTitle>
                    <InfoData>{GBPFormatter(nextPaymentAmount)}</InfoData>
                </InfoBox>
            </InfoRow>
        </Statement>
    );
};

export default StatementInfo;
