/* eslint-disable max-len */
import React from 'react';

const Money: React.FC = (props) => (
    <svg viewBox='0 0 25 24' fill='none' {...props}>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.666748 12C0.666748 5.37278 6.03965 0 12.6667 0C19.2938 0 24.6667 5.37278 24.6667 12C24.6667 18.6272 19.2938 24 12.6667 24C6.03965 24 0.666748 18.6272 0.666748 12ZM1.05384 12C1.05384 18.4037 6.26339 23.6133 12.6667 23.6133C19.0701 23.6133 24.2796 18.4037 24.2796 12C24.2796 5.59629 19.0701 0.386698 12.6667 0.386698C6.26339 0.386698 1.05384 5.59629 1.05384 12ZM10.8123 14.0679C10.8123 15.2866 10.2221 16.1654 9.49626 16.6012H16.6667V17H8.8821V16.6012C9.86316 15.9956 10.3337 15.1462 10.3337 14.0975C10.3337 13.6691 10.262 13.1226 10.1662 12.5391H8.66675V12.1403H10.1024C10.0913 12.067 10.0801 11.9935 10.0689 11.9198C9.95882 11.1994 9.84721 10.4686 9.84721 9.86558C9.84721 8.13737 11.0755 7 13.1493 7C15.1593 7 16.3796 8.06352 16.5232 9.57016L16.0446 9.61448C15.917 8.27031 14.7764 7.42836 13.1493 7.42836C11.4823 7.42836 10.3258 8.31462 10.3258 9.86558C10.3258 10.5229 10.4454 11.3575 10.573 12.1403H14.1942V12.5391H10.6368C10.7326 13.1521 10.8123 13.7061 10.8123 14.0679Z'
            fill='currentColor'
        />
    </svg>
);

export default Money;
