import { FC } from 'react';

import { Warning } from 'components/icons';
import { useTranslation } from 'react-i18next';

import {
    Container,
    Icon,
    Paragraph,
    StyledLink,
    Title,
} from './GlobalError.styles';

const GlobalError: FC = () => {
    const { t } = useTranslation('error');

    return (
        <Container>
            <Icon>
                <Warning />
            </Icon>
            <Title>{t('title')}</Title>
            <Paragraph>{t('paragraph-1')}</Paragraph>
            <Paragraph>{t('paragraph-2')}</Paragraph>
            <StyledLink href='mailto:myloan@selinafinance.co.uk?subject=Something went wrong'>
                {t('link')}
            </StyledLink>
        </Container>
    );
};

export default GlobalError;
