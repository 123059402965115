import { css } from '@emotion/react';

import { family, weight } from './fonts';

export const fontFamilyNeueSingularDRegular = `${family.neueSingular.standard}, sans-serif, -apple-system`;

export const textXs = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 1.2rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6rem;
`;

export const textSm = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 1.4rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 2rem;
`;

export const textBase = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 1.6rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 2.4rem;
`;

export const textLg = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 1.8rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 2.8rem;
`;

export const textXl = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 2rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 2.8rem;
`;

export const text2Xl = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 2.4rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 3.2rem;
`;

export const text3Xl = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 3rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 3.6rem;
`;

export const text4Xl = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 3.6rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 4rem;
`;

export const text5Xl = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 4.8rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 4.8rem;
`;

export const text6Xl = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 6rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 6rem;
`;

export const allCaps = css`
    text-transform: uppercase;
    letter-spacing: var(--global-letter-spacing);
`;

export const h1 = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 4rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    margin-bottom: 1rem;
`;

export const h2 = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 1.8rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
`;

export const h3 = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 2.8rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
`;

export const h4 = css`
    font-family: ${fontFamilyNeueSingularDRegular};
    font-size: 1.2rem;
    font-weight: ${weight.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.68;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    fontFamilyNeueSingularDRegular,
    weight,
    textXs,
    textSm,
    textBase,
    textLg,
    textXl,
    text2Xl,
    text3Xl,
    text4Xl,
    text5Xl,
    text6Xl,
    h1,
    h2,
    h3,
    h4,
    p: {
        small: css`
            font-family: ${fontFamilyNeueSingularDRegular};
            font-size: 1.1rem;
            font-weight: ${weight.regular};
            font-stretch: normal;
            font-style: normal;
            line-height: 1.46;
            text-transform: initial;
        `,
        large: css`
            font-family: ${fontFamilyNeueSingularDRegular};
            font-size: 1.6rem;
            font-weight: ${weight.regular};
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
        `,
        default: css`
            font-family: ${fontFamilyNeueSingularDRegular};
            font-size: 1.6rem;
            font-weight: ${weight.regular};
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            text-transform: none;
        `,
        strikethrough: css`
            text-decoration: line-through;
        `,
    },
    a: css`
        color: inherit;
        font-family: ${fontFamilyNeueSingularDRegular};
        font-size: 1.6rem;
        font-weight: ${weight.regular};
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    `,
};
