import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { white } from 'theme/colors';

export const StyledLoading = styled(CircularProgress)`
    color: ${white};
`;

export const CenteredDiv = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 0;
`;
