/* eslint-disable max-len */
import React from 'react';

const CuttedIconBackground: React.FC = (props) => (
    <svg viewBox='0 0 375 333' fill='none' {...props}>
        <g opacity='0.25' clipPath='url(#clip0)'>
            <path
                d='M29.6042 4.70571C13.9185 2.35285 -0.512405 1.098 -15.4138 0L387.395 238.109C347.671 176.099 295.237 123.229 233.559 82.9934C171.88 42.7579 102.363 16.0738 29.6042 4.70571Z'
                fill='#4D5358'
            />
            <path
                d='M427.393 312.617L-159.879 10.5103C-214.139 22.0655 -266.196 42.2422 -314.069 70.2727L443.863 354.497C439.052 340.38 433.562 326.42 427.393 312.617Z'
                fill='#4D5358'
            />
            <path
                d='M457.51 402.18L-402.693 135.524C-439.458 169.275 -471.156 208.159 -496.807 250.971L463 430.101C461.274 420.846 459.706 411.435 457.51 402.18Z'
                fill='#4D5358'
            />
        </g>
        <defs>
            <clipPath id='clip0'>
                <rect
                    width='1042'
                    height='1042'
                    fill='white'
                    transform='translate(-571)'
                />
            </clipPath>
        </defs>
    </svg>
);

export default CuttedIconBackground;
