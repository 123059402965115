import { ReactNode } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import useFacility from 'helpers/useFacility';
import useProfile from 'helpers/useProfile';

import GlobalError from '../error/GlobalError';

import { CenteredDiv } from './Loading.styles';

interface Props {
    children: ReactNode;
}

const Loading = ({ children }: Props) => {
    const { isIdle, isLoading, facilityError } = useFacility();
    const { error } = useProfile();

    if (facilityError || error) {
        return <GlobalError />;
    }

    return isIdle || isLoading ? (
        <CenteredDiv>
            <CircularProgress />
        </CenteredDiv>
    ) : (
        <>{children}</>
    );
};

export default Loading;
