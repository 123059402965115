import styled from '@emotion/styled';
import { grey100 } from 'theme/colors';
import * as media from 'theme/custom-media';

export const StyledContainer = styled.div`
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    background-color: ${grey100};

    & > main {
        flex-grow: 1;

        ${media.lteSmallMedia} {
            margin-bottom: calc(var(--mobile-nav-height) + 2rem);
        }
    }
`;
