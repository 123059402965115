import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { HalfIconBackground } from 'components/icons';
import { Link } from 'react-router-dom';
import { gradientBG, grey500, white } from 'theme/colors';
import * as media from 'theme/custom-media';

export const StyledLogoLink = styled(Link)`
    display: flex;
    justify-content: center;
    width: 188px;
    color: ${white};
    margin: 8rem auto;
    line-height: 0;
    position: relative;

    & > svg {
        z-index: 1;
    }

    ${media.gteMediumMedia} {
        width: 26.3rem;
        margin: 0 auto 3.6rem;
    }
`;

export const LogoMarkContainer = styled.div`
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const StyledContainer = styled.div`
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    padding: 0 1.6rem;
    color: ${white};
    background: ${gradientBG};

    .MuiInputBase-root,
    .MuiFormLabel-root {
        color: ${white};
        font-size: 1.8rem;
        line-height: 2.7rem;
    }

    .MuiInputBase-input {
        padding: 1.4rem 1.6rem;
    }

    .MuiFormHelperText-root {
        padding: 0 1.6rem;
    }

    .MuiOutlinedInput-input {
        padding: 1.4rem 1.6rem;
    }

    .MuiInput-underline {
        &:before,
        &:after {
            display: none;
        }
    }

    ${media.gteMediumMedia} {
        justify-content: center;
    }
`;

export const StyledDiv = styled.div`
    position: relative;
    z-index: 1;
    flex-grow: 1;
    max-width: 34.3rem;
    margin: 0 auto;

    ${media.gteMediumMedia} {
        flex-grow: unset;
        margin: 8rem auto;
    }
`;

export const StyledLink = styled(Link)`
    display: block;
    font-size: 1.6rem;
    line-height: 2rem;
    color: ${grey500};
    text-align: center;
    margin-top: 3.2rem;
`;

export const StyledForm = styled.form`
    & > div {
        margin-bottom: 2.4rem;
    }
`;

export const StyledButton = styled(Button)`
    //height: 5.6rem;
    //border-radius: 2.8rem;
`;

export const StyledAlert = styled(Alert)`
    margin: 2rem 0;

    & span {
        margin-right: 1rem;
    }
`;

export const StyledParagraph = styled.p`
    margin-bottom: 2rem;

    & > span {
        margin-right: 1rem;
    }
`;

export const StyledButtonAsLink = styled.button`
    text-decoration: underline;
    color: inherit;
    background-color: inherit;
    border: 0;
    padding: 0;
    cursor: pointer;
`;

export const BackgroundIcon = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
`;

export const HalfLogoBackground = styled(HalfIconBackground)`
    position: absolute;
    left: 0;
    height: 100%;
`;

export const InputsContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 0.4rem;
    border: 0.1rem solid rgba(255, 255, 255, 0.14);
    overflow: hidden;

    & > div:not(:last-child):not(:only-child) {
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.14);
    }
`;
