import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { getDaysBetweenDates, getYearsByMonths } from 'utils/dates';

import { RootState } from '../buildStore';

import { setFacilityReducer, setRepaymentAmountReducer } from './reducers';

export type SliceState = {
    statements?: Statements;
    pendingAmount: number;
    usedFund: number;
    availableFund: number;
    repaymentAmount?: number;
    bankDetails?: BankDetails;
};

export type Statements = [
    {
        id?: string;
        status?: string;
        purpose?: string;
        amount?: number;
        type?: string;
        date?: string;
        datetime?: string;
    },
];

export interface BankDetails {
    bankAccountNumber?: string;
    bankName?: string;
    bankSortCode?: string;
}

const initialState: SliceState = {
    usedFund: 0,
    availableFund: 0,
    pendingAmount: 0,
    repaymentAmount: undefined,
};

const slice = createSlice({
    name: 'facility',
    initialState,
    reducers: {
        setFacility: setFacilityReducer,
        setRepaymentAmount: setRepaymentAmountReducer,
        resetFacility: () => initialState,
    },
});

export const getFacility = (state: RootState) => state.facility;

export const getPendingAmount = (state: RootState) =>
    get(getFacility(state), 'pendingAmount');

export const getUsedFund = (state: RootState) =>
    get(getFacility(state), 'currentMortgageBalance', 0);

export const getCreditLimit = (state: RootState) =>
    get(getFacility(state), 'currentCreditLimit', 0);

export const getAvailableFund = (state: RootState) =>
    get(getFacility(state), 'balanceAvailableForDrawdown', 0) -
    getPendingAmount(state);

export const getOwePercentage = (state: RootState) =>
    (getUsedFund(state) / getCreditLimit(state)) * 100;

export const getPendingPercentage = (state: RootState) =>
    ((getPendingAmount(state) + getUsedFund(state)) / getCreditLimit(state)) *
    100;

export const getRepayments = (state: RootState) =>
    get(getFacility(state), 'repayments');

export const getNextPaymentDue = (state: RootState) =>
    get(getRepayments(state), 'nextPaymentDue');

export const getNextPaymentDueInDays = (state: RootState) =>
    getDaysBetweenDates(getNextPaymentDue(state));

export const getProductDetails = (state: RootState) =>
    get(getFacility(state), 'productDetails');

export const getInterestRate = (state: RootState) =>
    Number(get(getProductDetails(state), 'interestRate', 0) * 100)?.toFixed(2);

export const getAprc = (state: RootState) =>
    Number(get(getProductDetails(state), 'aprc', 0) * 100)?.toFixed(2);

export const getTerm = (state: RootState) =>
    get(getProductDetails(state), 'term', 0);

export const getProductFee = (state: RootState) =>
    get(getProductDetails(state), 'productFee');

export const getTermInYears = (state: RootState) =>
    getYearsByMonths(getTerm(state));

export const getNextPaymentAmount = (state: RootState) =>
    get(getRepayments(state), 'currentMonthlyPayment', 0);

export const getRemainingPayments = (state: RootState) =>
    get(getRepayments(state), 'remainingPayments');

export const getStatements = (state: RootState) =>
    get(getFacility(state), 'statements', []);

export const getBankDetails = (state: RootState) =>
    get(getFacility(state), 'bankDetails');

export const getBankAccountNumber = (state: RootState) =>
    get(getBankDetails(state), 'bankAccountNumber', '');

export const getBankName = (state: RootState) =>
    get(getBankDetails(state), 'bankName', '');

export const getPropertyDetails = (state: RootState) =>
    get(getFacility(state), 'propertyDetails');

export const getRepaymentAmount = (state: RootState) =>
    get(getFacility(state), 'repaymentAmount');

export const { setFacility, resetFacility, setRepaymentAmount } = slice.actions;

export default slice.reducer;
