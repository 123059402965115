/* eslint-disable max-len */
import React from 'react';

const ArrowLeft: React.FC = (props) => (
    <svg viewBox='0 0 26 18' fill='none' {...props}>
        <path
            fill='currentColor'
            d='M9.47157 1.47132C9.73192 1.21097 9.73192 0.788864 9.47157 0.528514C9.21122 0.268165 8.78911 0.268165 8.52876 0.528514L0.52876 8.50263C0.394346 8.63704 0.329357 8.8096 0.333762 8.98092C0.333585 8.98723 0.333496 8.99357 0.333496 8.99992C0.333496 9.25727 0.479317 9.48056 0.69284 9.59168L8.52876 17.4713C8.78911 17.7317 9.21122 17.7317 9.47157 17.4713C9.73192 17.211 9.73192 16.7889 9.47157 16.5285L2.60964 9.66658H25.0002C25.3684 9.66658 25.6668 9.36811 25.6668 8.99992C25.6668 8.63173 25.3684 8.33325 25.0002 8.33325H2.60964L9.47157 1.47132Z'
            fillRule='evenodd'
            clipRule='evenodd'
        />
    </svg>
);

export default ArrowLeft;
