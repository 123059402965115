/* eslint-disable max-len */
import React from 'react';

const ChevronDown: React.FC = (props) => (
    <svg viewBox='0 0 10 7' fill='none' {...props}>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.292893 0.792924C0.683416 0.402399 1.31658 0.402399 1.7071 0.792924L4.99999 4.08582L8.29288 0.792924C8.6834 0.402399 9.31657 0.402399 9.70709 0.792924C10.0976 1.18345 10.0976 1.81661 9.70709 2.20714L5.7071 6.20714C5.31657 6.59766 4.68341 6.59766 4.29289 6.20714L0.292893 2.20714C-0.0976309 1.81661 -0.0976309 1.18345 0.292893 0.792924Z'
            fill='currentColor'
        />
    </svg>
);

export default ChevronDown;
