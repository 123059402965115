import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'state/buildStore';

export enum SERVICING_PROVIDER {
    PEPPER = 'Pepper',
    PHOEBUS = 'Phoebus',
}

export enum APPLICATION_TYPE {
    HOL = 'HOL',
    HELOC = 'HELOC',
}

export enum FUNDING_PROVIDER {
    WAM = 'Warehouse',
    VANQUIS = 'Vanquis',
    CANTERBURY_FUNDING_LIMITED = 'Canterbury Funding Limited',
}
export interface ProfileResponse {
    applicationId?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    applicationType: APPLICATION_TYPE;
    servicingProvider: SERVICING_PROVIDER;
    fundingProvider: FUNDING_PROVIDER;
}

export const api = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: window._env_.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.authToken?.[0];
            const idToken = (getState() as RootState)?.auth?.authToken?.[2];

            if (token) {
                headers.set('authorization', `Bearer ${token}`);
                headers.set('x-user-profile', `Bearer ${idToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getProfile: builder.query<ProfileResponse, string>({
            query: () => 'profile/email-address',
        }),
    }),
});

export const { useGetProfileQuery } = api;
