import useAuth from 'helpers/useAuth';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import appRoutes from 'utils/routes';

const PrivateRoute = ({
    children,
    allowedGroups,
    ...rest
}: RouteProps & { allowedGroups?: string[] }) => {
    const { isLoggedIn, roles } = useAuth();

    const isAuthorised =
        (isLoggedIn && allowedGroups && allowedGroups.length > 0
            ? roles?.some((role) => {
                  return allowedGroups.includes(role);
              })
            : isLoggedIn) ?? false;

    //wait till we know if the user is logged in or not
    if (isLoggedIn === null) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={() => {
                if (!isAuthorised) {
                    if (isLoggedIn) {
                        return <Redirect to={appRoutes.home.root} />;
                    } else {
                        return <Redirect to={appRoutes.login.root} />;
                    }
                }

                return children;
            }}
        />
    );
};

export default PrivateRoute;
