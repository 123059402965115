import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'state/buildStore';

import {
    APPLICATION_TYPE,
    FUNDING_PROVIDER,
    SERVICING_PROVIDER,
} from '../profile/profile';

export interface FacilityResponse {}

export interface FacilityArgs {
    applicationId: string;
    body: object;
}

export interface RepaymentArgs {
    applicationId: string;
    requestedAmount: number | null;
}

export interface RepaymentResponse {
    amount: number;
}

export interface getProfileByServicingIdResponseBody {
    applicationId: string;
    firstName: string;
    lastName: string;
    servicingId: string;
    applicationType: APPLICATION_TYPE;
    servicingProvider: SERVICING_PROVIDER;
    fundingProvider: FUNDING_PROVIDER;
}

export const api = createApi({
    reducerPath: 'facilityApi',
    baseQuery: fetchBaseQuery({
        baseUrl: window._env_.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.authToken?.[0];
            const idToken = (getState() as RootState)?.auth?.authToken?.[2];

            if (token) {
                headers.set('authorization', `Bearer ${token}`);
                headers.set('x-user-profile', `Bearer ${idToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getFacility: builder.query<FacilityResponse, string>({
            query: (applicationId: string) => `facility/${applicationId}`,
        }),
        getRepaymentAmount: builder.query<RepaymentResponse, RepaymentArgs>({
            query: ({ applicationId, requestedAmount }) =>
                `facility/${applicationId}/repayments?requestedAmount=${requestedAmount}`,
        }),
        postFacility: builder.mutation<FacilityResponse, FacilityArgs>({
            query: ({ applicationId, body }) => ({
                url: `facility/${applicationId}/drawdown`,
                method: 'POST',
                body: body,
            }),
        }),
        getProfileByServicingId: builder.query<
            getProfileByServicingIdResponseBody,
            string
        >({
            query: (servicingId) => ({
                url: `profile/servicing-id/${servicingId}`,
            }),
        }),
    }),
});

export const {
    useGetFacilityQuery,
    useGetRepaymentAmountQuery,
    usePostFacilityMutation,
    useGetProfileByServicingIdQuery,
} = api;
