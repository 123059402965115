import { ReactNode, useEffect } from 'react';
import { useState } from 'react';

import { Modal } from '@material-ui/core';
import Banner from 'components/Banner';
import { RoundButtonSmall } from 'components/atoms/Button';
import Text from 'components/atoms/text';
import Disclaimer from 'components/disclaimer/Disclaimer';
import Header from 'components/header/Header';
import GlobalLoading from 'components/loading/GlobalLoading';
import NavBar from 'components/navbar/NavBar';
import useAuth from 'helpers/useAuth';
import useProfile from 'helpers/useProfile';
import useViewport from 'helpers/useViewport';
import mixpanel from 'mixpanel-browser';
import { useHistory, useLocation } from 'react-router-dom';
import { SliceState, setImpersonation } from 'state/auth/slice';
import { useAppDispatch } from 'state/buildStore';
import appRoutes from 'utils/routes';

import { StyledContainer } from './styles';

const acceptedKey = 'acceptedImpersonation';

const DashboardTemplate = ({ children }: { children: ReactNode }) => {
    const { gteMedium: isDesktop } = useViewport();
    const { firstName, lastName } = useProfile();
    const { impersonation, roles } = useAuth();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation<{
        impersonation: SliceState['impersonation'];
    }>();

    useEffect(() => {
        if (location.state?.impersonation) {
            dispatch(setImpersonation(location.state.impersonation));
        } else if (roles?.includes('ServicingAgent')) {
            history.replace(appRoutes.admin.root);
        }
    }, [location.state?.impersonation, dispatch, history, roles]);

    const accepted = localStorage.getItem(acceptedKey);
    const [acceptedImpersonation, setAcceptedImpersonation] = useState<boolean>(
        (accepted && JSON.parse(accepted)) || false,
    );

    useEffect(() => {
        if (firstName) {
            mixpanel.people.set({
                $name: `${firstName}${lastName ? ' ' + lastName : ''}`,
                $email: localStorage?.user_email,
            });
        }
    }, [firstName, lastName]);

    return (
        <StyledContainer>
            {!!impersonation && (
                <>
                    <Banner variant='Success'>
                        <Text colour='White'>
                            You are now {impersonation.firstName}{' '}
                            {impersonation.lastName}
                        </Text>
                        <RoundButtonSmall
                            onClick={() => {
                                dispatch(setImpersonation(null));
                                localStorage.removeItem(acceptedKey);
                                history.push('/admin');
                            }}
                        >
                            <Text variant='paragraphSmall' colour='Steel100'>
                                Exit
                            </Text>
                        </RoundButtonSmall>
                    </Banner>

                    <Modal open={!acceptedImpersonation}>
                        <>
                            <Banner variant='Success'>
                                <Text colour='White'>
                                    You are now {impersonation.firstName}{' '}
                                    {impersonation.lastName}
                                </Text>
                                <RoundButtonSmall
                                    onClick={() => {
                                        setAcceptedImpersonation(true);
                                        localStorage.setItem(
                                            acceptedKey,
                                            JSON.stringify(true),
                                        );
                                    }}
                                >
                                    <Text
                                        variant='paragraphSmall'
                                        colour='Steel100'
                                    >
                                        Okay
                                    </Text>
                                </RoundButtonSmall>
                            </Banner>
                        </>
                    </Modal>
                </>
            )}
            <Disclaimer />
            <Header />
            <main>
                <GlobalLoading>{children}</GlobalLoading>
            </main>
            {!isDesktop && <NavBar />}
        </StyledContainer>
    );
};

export default DashboardTemplate;
