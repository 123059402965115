/* eslint-disable max-len */
import React from 'react';

const Lock: React.FC = (props) => (
    <svg viewBox='0 0 12 16' fill='none' {...props}>
        <path
            d='M10.6233 6.70457H9.92833V4.58847C9.92833 2.4262 8.1692 0.666672 6.00704 0.666672C3.8441 0.666672 2.08528 2.42596 2.08528 4.58847V6.70457H1.39055C0.990706 6.70457 0.666748 7.02868 0.666748 7.42837V14.6096C0.666748 15.0093 0.990706 15.3333 1.39055 15.3333H10.6233C11.0224 15.3333 11.3471 15.0093 11.3471 14.6096V7.42837C11.3471 7.02868 11.0223 6.70457 10.6233 6.70457ZM3.53273 4.58847C3.53273 3.22391 4.64248 2.11416 6.007 2.11416C7.37121 2.11416 8.48101 3.22391 8.48101 4.58847V6.70457H3.53273V4.58847Z'
            fill='currentColor'
        />
    </svg>
);

export default Lock;
