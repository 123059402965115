import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Loading from 'components/loading/Loading';
import useAuth from 'helpers/useAuth';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ResetPasswordModel } from 'shared/auth/model/reset-password.model';
import appRoutes from 'utils/routes';

import {
    InputsContainer,
    StyledAlert,
    StyledButton,
    StyledButtonAsLink,
    StyledForm,
    StyledParagraph,
} from '../../Authentication.styles';

import { StyledLoading } from './VerifyCode.styles';
import { schema } from './schema';

const VerifyCode: FC = () => {
    const { t } = useTranslation('authentication');
    const history = useHistory();
    const { search } = useLocation();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [hasError, setHasError] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm<ResetPasswordModel>({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        mode: 'onChange',
    });
    const {
        actions: { login, setLoginResult },
        loginResult,
        authService,
    } = useAuth();

    useEffect(() => {
        if (!authService.recoverEmail?.length) {
            history.push(appRoutes.recover.root + search);
        } else {
            setEmail(authService.recoverEmail);
        }

        return () => {
            setLoginResult('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loginResult === 'success') {
            history.push(appRoutes.home.root + search);
        }
    }, [history, loginResult, search, setLoginResult]);

    const resendCode = useCallback(() => {
        if (isResending) {
            return;
        }

        setIsResending(true);
        authService
            .requestPasswordRecovery({
                email,
            })
            .then(() => {
                setIsResending(false);
            });
    }, [authService, email, isResending]);

    const onSubmit = useCallback(
        (data: ResetPasswordModel) => {
            setIsLoading(true);
            setHasError(false);

            const value: ResetPasswordModel = {
                ...data,
                email,
            };
            authService.resetPassword(value).then((result: boolean) => {
                if (result) {
                    authService.recoverEmail = null;
                    login({
                        email,
                        password: data.password,
                    });
                } else {
                    setIsLoading(false);
                    setHasError(true);
                }
            });
        },
        [authService, email, login],
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>): void => {
            // Ignore space key
            if (event.key === ' ') {
                event.preventDefault();
            }

            hasError && isValid && setHasError(false);
        },
        [hasError, isValid],
    );

    return (
        <div>
            <h1>{t('reset.title')}</h1>
            <StyledParagraph>{t('reset.sub-title')}</StyledParagraph>
            <StyledParagraph>
                <span>{t('reset.resend.label')}</span>
                <StyledButtonAsLink onClick={resendCode} disabled={isResending}>
                    {t('reset.resend.link')}
                    {isResending && <StyledLoading size='1.5rem' />}
                </StyledButtonAsLink>
            </StyledParagraph>

            {hasError && (
                <StyledAlert severity='error'>
                    {t('reset.form.error')}
                </StyledAlert>
            )}

            <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
                <InputsContainer>
                    <TextField
                        {...register('code')}
                        id='code'
                        placeholder={t('confirm.form.code')}
                        type='text'
                        error={!!errors.code}
                        helperText={
                            errors?.code?.type === 'max' &&
                            errors?.code?.message
                        }
                        fullWidth
                        onKeyDown={handleKeyDown}
                    />
                    <TextField
                        {...register('password')}
                        id='password'
                        placeholder={t('reset.form.password')}
                        type='password'
                        error={!!errors.password}
                        helperText={
                            errors?.password?.type === 'matches' &&
                            errors?.password?.message
                        }
                        fullWidth
                        onKeyDown={handleKeyDown}
                    />
                    <TextField
                        {...register('confirmPassword')}
                        id='confirmPassword'
                        placeholder={t('reset.form.confirm-password')}
                        type='password'
                        error={!!errors.confirmPassword}
                        helperText={
                            errors?.confirmPassword?.type === 'oneOf' &&
                            errors?.confirmPassword?.message
                        }
                        fullWidth
                        onKeyDown={handleKeyDown}
                    />
                </InputsContainer>
                <StyledButton
                    variant='contained'
                    color='secondary'
                    type='submit'
                    fullWidth
                    disabled={isLoading || !isDirty || !isValid}
                >
                    {isLoading ? <Loading /> : t('reset.form.submit')}
                </StyledButton>
            </StyledForm>
        </div>
    );
};

export default VerifyCode;
