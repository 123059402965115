import { FC, Fragment } from 'react';

import {
    CircularProgressbar as Circular,
    CircularProgressbarWithChildren,
    buildStyles,
} from 'react-circular-progressbar';
import { grey200, grey500, grey900 } from 'theme/colors';

import {
    BackgroundContainer,
    BackgroundImage,
    CircularContainer,
} from './CircularProgressbar.styles';

type Props = {
    children: any;
    value: number;
    stroke?: number;
    pendingPercentage?: number;
};

const CircularProgressbar: FC<Props> = ({
    children,
    value,
    stroke,
    pendingPercentage,
}) => {
    const strokeWidth = stroke || 9;

    return (
        <Fragment>
            <BackgroundContainer>
                <BackgroundImage />
            </BackgroundContainer>
            <CircularProgressbarWithChildren
                value={pendingPercentage || value}
                strokeWidth={strokeWidth}
                styles={buildStyles({
                    pathColor: pendingPercentage ? grey500 : grey900,
                    trailColor: grey200,
                    strokeLinecap: 'butt',
                    pathTransitionDuration: 0.3,
                })}
            >
                {children}
                {!!pendingPercentage && (
                    <CircularContainer>
                        <Circular
                            value={value}
                            strokeWidth={strokeWidth}
                            styles={buildStyles({
                                pathColor: grey900,
                                trailColor: 'transparent',
                                strokeLinecap: 'butt',
                                pathTransitionDuration: 0.3,
                            })}
                        />
                    </CircularContainer>
                )}
            </CircularProgressbarWithChildren>
        </Fragment>
    );
};

export default CircularProgressbar;
