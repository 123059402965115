import { FC } from 'react';

import {
    BackCircularProgress,
    Container,
    FrontCircularProgress,
} from './Spinner.styles';

const Loading: FC = () => {
    const size = 30;
    const thickness = 4;

    return (
        <Container>
            <BackCircularProgress
                variant='determinate'
                size={size}
                thickness={thickness}
                value={100}
            />
            <FrontCircularProgress
                variant='indeterminate'
                disableShrink
                size={size}
                thickness={thickness}
            />
        </Container>
    );
};

export default Loading;
