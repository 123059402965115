// @ts-nocheck
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import * as media from 'theme/custom-media';

/**
 * Combines a breakpoint with its respective media watcher.
 *
 * @typedef {Object} BreakpointWatcher
 * @property {String} name - The name of the breakpoint
 * @property {MediaQueryList} query - The result of window.matchMedia
 */

type Watcher = {
    name: string;
    query: {
        matches: any;
    };
};

function reduceBreakpointMatchers(breakpointWatchers: Watcher[] = []) {
    return breakpointWatchers.reduce(
        (breakpoints, watcher) => ({
            ...breakpoints,
            [watcher.name]: !!watcher.query.matches,
        }),
        {},
    );
}

/**
 * Transforms the breakpoint watchers into a map where each key is
 * the name of the breakpoint and the value whether or not that
 * breakpoint matches with the current viewport.
 *
 * @param {Object} mediaWatchers - The watchers to transform.
 * @returns {Map<String,Boolean>} - A map where each key is the breakpoint
 *  name and the value its matching state with the current viewport.
 */

type MediaWatcher = {
    clientMatchers?: [];
};

function getBreakpointMatches(mediaWatchers: MediaWatcher = {}) {
    const clientBreakpointMatches = reduceBreakpointMatchers(
        mediaWatchers.clientMatchers,
    );

    return {
        ...clientBreakpointMatches,
    };
}

const breakpoints = {
    extraSmall: media.extraSmall,
    small: media.small,
    medium: media.medium,
    gteSmall: media.gteSmall,
    gteMedium: media.gteMedium,
    lteSmall: media.lteSmall,
    lteMedium: media.lteMedium,
};

type ViewportContextTypes = {
    extraSmall: boolean;
    small: boolean;
    medium: boolean;
    large: boolean;
    gteSmall: boolean;
    gteMedium: boolean;
    lteSmall: boolean;
    lteMedium: boolean;
};

const ViewportContext = React.createContext<Partial<ViewportContextTypes>>({});

export const ViewportProvider = ({ ...props }) => {
    const isTouchDevice = useMemo(
        () =>
            'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0,
        [],
    );

    const mediaWatchers = useMemo(() => {
        const clientMatchers = Object.entries(breakpoints).map(
            ([breakpoint, query]) => ({
                name: breakpoint,
                query:
                    typeof window !== 'undefined' && window.matchMedia
                        ? window.matchMedia(query)
                        : {},
            }),
        );

        return {
            clientMatchers,
        };
    }, []);

    const [breakpoint, setBreakpoint] = useState(
        getBreakpointMatches(mediaWatchers),
    );

    const update = useCallback(() => {
        setBreakpoint(getBreakpointMatches(mediaWatchers));
    }, [mediaWatchers]);

    useEffect(() => {
        for (const { query } of mediaWatchers.clientMatchers) {
            if (query.addListener) {
                query.addListener(update);
            }
        }

        return () => {
            for (const { query } of mediaWatchers.clientMatchers) {
                if (query.removeListener) {
                    query.removeListener(update);
                }
            }
        };
    }, [mediaWatchers, update]);

    const value = useMemo(
        () => ({ ...breakpoint, isTouchDevice }),
        [breakpoint, isTouchDevice],
    );

    return <ViewportContext.Provider value={value} {...props} />;
};

export function useViewport(): Partial<ViewportContextTypes> {
    return useContext(ViewportContext);
}
export default useViewport;
