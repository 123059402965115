import styled from '@emotion/styled';
import { grey500, white } from 'theme/colors';

export const StyledNav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: var(--mobile-nav-height);
    color: ${grey500};
    background-color: ${white};

    & svg {
        height: 2.4rem;
    }

    & p {
        font-size: 1.2rem;
        line-height: 1.56rem;
        margin-top: 0.2rem;
    }

    & a {
        text-align: center;
        text-decoration: none;
    }

    & a,
    button {
        line-height: 0;
    }
`;
