/* eslint-disable max-len */
import React from 'react';

const LogoSelina: React.FC = (props) => (
    <svg viewBox='0 0 282 282' fill='none' {...props}>
        <g opacity='0.13' clipPath='url(#clip0)'>
            <path
                d='M102.815 -56.0897C96.4477 -57.0449 90.5895 -57.5543 84.5403 -58L248.061 38.6602C231.935 13.4875 210.649 -7.9752 185.611 -24.3088C160.572 -40.6424 132.352 -51.4748 102.815 -56.0897Z'
                fill='white'
            />
            <path
                d='M280.981 133.028L-128.902 81.323C-133.606 94.3155 -137.06 107.727 -139.217 121.375C-140.109 127.233 -140.554 133.028 -141 138.823L281.936 142.579C281.745 139.396 281.3 136.148 280.981 133.028Z'
                fill='url(#paint0_linear)'
            />
            <path
                d='M251.754 263.692L95.4934 365C127.532 361.119 158.262 349.975 185.341 332.419C212.42 314.862 235.135 291.356 251.754 263.692Z'
                fill='url(#paint1_linear)'
            />
            <path
                d='M264.298 68.9067L25.8946 -53.7334C3.86792 -49.0425 -17.2646 -40.8518 -36.699 -29.4728L270.984 85.9082C269.031 80.1773 266.802 74.5102 264.298 68.9067Z'
                fill='url(#paint2_linear)'
            />
            <path
                d='M-140.045 179.193C-137.721 198.729 -132.684 217.846 -125.082 235.992L281.681 167.795C281.681 164.547 281.681 161.427 281.999 158.116L-140.045 179.193Z'
                fill='url(#paint3_linear)'
            />
            <path
                d='M36.2097 363.79L266.908 233C269.2 227.269 271.174 221.474 273.276 215.552L-27.53 342.522C-7.56182 352.985 13.9599 360.166 36.2097 363.79Z'
                fill='url(#paint4_linear)'
            />
            <path
                d='M-64.5885 317.689L277.798 195.876C278.434 192.82 279.199 189.827 279.708 186.643C279.708 185.879 279.708 185.115 279.708 184.351L-105.469 272.542C-94.0501 289.452 -80.2857 304.653 -64.5885 317.689Z'
                fill='url(#paint5_linear)'
            />
            <path
                d='M276.524 105.265L-72.6755 -2.98413C-87.6002 10.7172 -100.468 26.5019 -110.881 43.8814L278.753 116.599C278.052 112.842 277.415 109.022 276.524 105.265Z'
                fill='url(#paint6_linear)'
            />
        </g>
        <defs>
            <linearGradient
                id='paint0_linear'
                x1='282'
                y1='142.226'
                x2='-45.3966'
                y2='116.071'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#A0A086' stopOpacity='0.72' />
                <stop offset='0.34439' stopColor='#A0A086' />
                <stop offset='0.967129' stopColor='#A0A086' stopOpacity='0' />
            </linearGradient>
            <linearGradient
                id='paint1_linear'
                x1='256.746'
                y1='270.299'
                x2='54.7163'
                y2='398.371'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#A0A086' stopOpacity='0.54' />
                <stop
                    offset='0.353911'
                    stopColor='#A0A086'
                    stopOpacity='0.64'
                />
                <stop offset='1' stopColor='#A0A086' stopOpacity='0' />
            </linearGradient>
            <linearGradient
                id='paint2_linear'
                x1='249.695'
                y1='60.7271'
                x2='35.7102'
                y2='-21.8296'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#9A9A7E' />
                <stop offset='1' stopColor='#CACABA' stopOpacity='0.26' />
            </linearGradient>
            <linearGradient
                id='paint3_linear'
                x1='282'
                y1='158.242'
                x2='-128.67'
                y2='215.148'
                gradientUnits='userSpaceOnUse'
            >
                <stop
                    offset='0.264122'
                    stopColor='#A0A086'
                    stopOpacity='0.74'
                />
                <stop
                    offset='0.757254'
                    stopColor='#A0A086'
                    stopOpacity='0.22'
                />
            </linearGradient>
            <linearGradient
                id='paint4_linear'
                x1='259.083'
                y1='226.393'
                x2='-5.56003'
                y2='363.666'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#A0A086' stopOpacity='0.64' />
                <stop
                    offset='0.652513'
                    stopColor='#A0A086'
                    stopOpacity='0.37'
                />
            </linearGradient>
            <linearGradient
                id='paint5_linear'
                x1='282'
                y1='188.675'
                x2='-81.6364'
                y2='290.174'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#A0A086' stopOpacity='0.39' />
                <stop
                    offset='0.568085'
                    stopColor='#A0A086'
                    stopOpacity='0.51'
                />
                <stop offset='1' stopColor='#A0A086' stopOpacity='0' />
            </linearGradient>
            <linearGradient
                id='paint6_linear'
                x1='240.86'
                y1='101.315'
                x2='-12.6088'
                y2='35.601'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#929279' />
                <stop offset='1' stopColor='#A0A086' stopOpacity='0.07' />
            </linearGradient>
            <clipPath id='clip0'>
                <rect
                    width='423'
                    height='423'
                    fill='white'
                    transform='translate(-141 -58)'
                />
            </clipPath>
        </defs>
    </svg>
);

export default LogoSelina;
