import { FC, useCallback } from 'react';

import { BarGraph, Money, OnlineForm } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { grey950 } from 'theme/colors';
import appRoutes from 'utils/routes';

// import useProperty from '../../helpers/useProperty';
import { StyledNav } from './NavBar.styles';

const NavBar: FC = () => {
    const { t } = useTranslation('nav');
    const { pathname, search } = useLocation();
    // const { valuations } = useProperty();

    const linkActiveStyle = useCallback(
        (route: string) => {
            return { color: pathname === route ? grey950 : 'inherit' };
        },
        [pathname],
    );

    const normLink = useCallback((route: string) => route + search, [search]);

    return (
        <StyledNav>
            <Link
                to={normLink(appRoutes.home.root)}
                style={linkActiveStyle(appRoutes.home.root)}
            >
                <BarGraph />
                <p>{t('dashboard')}</p>
            </Link>
            {/*{!!valuations?.length && (*/}
            {/*    <Link*/}
            {/*        to={normLink(appRoutes.propertyValuation.root)}*/}
            {/*        style={linkActiveStyle(appRoutes.propertyValuation.root)}*/}
            {/*    >*/}
            {/*        <Home />*/}
            {/*        <p>{t('my-home')}</p>*/}
            {/*    </Link>*/}
            {/*)}*/}
            <Link
                to={normLink(appRoutes.home.children.statements.root)}
                style={linkActiveStyle(appRoutes.home.children.statements.root)}
            >
                <OnlineForm />
                <p>{t('statements')}</p>
            </Link>
            <Link
                to={normLink(appRoutes.home.children.productDetails.root)}
                style={linkActiveStyle(
                    appRoutes.home.children.productDetails.root,
                )}
            >
                <Money />
                <p>{t('product')}</p>
            </Link>
        </StyledNav>
    );
};

export default NavBar;
