import styled from '@emotion/styled';
import { grey950, orange100 } from 'theme/colors';
import * as media from 'theme/custom-media';

export const Container = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.82rem;
    height: 4.4rem;
    padding: 0rem 1.4rem;
    border-radius: var(--global-border-radius);
    background-color: ${orange100};
    color: ${grey950};

    & span {
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 500;
        margin-left: 0.8rem;
    }

    ${media.gteMediumMedia} {
        display: flex;
        align-items: center;
        height: 3.4rem;
    }
`;
