import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import last from 'lodash/last';

import { RootState } from '../buildStore';

import { setPropertyReducer } from './reducers';

export type SliceState = {
    suggestions: object;
    valuations: [];
};

const initialState: SliceState = {
    suggestions: {},
    valuations: [],
};

const slice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        setProperty: setPropertyReducer,
        resetProperty: () => initialState,
    },
});

export const getPropertySuggestions = (state: RootState) =>
    state.property.suggestions;

export const getPropertyValuations = (state: RootState) =>
    state.property.valuations;

// Estimated Value - most recent valuation index
export const getPropertyEstimatedValue = (state: RootState) =>
    get(last(getPropertyValuations(state)), 'valuationIndex', 0);

export const getPropertyMortgage = (state: RootState) =>
    get(state, 'facility.currentMortgageBalance', 0);

export const getPropertyEquityValue = (state: RootState) =>
    getPropertyEstimatedValue(state) - getPropertyMortgage(state);

export const { setProperty, resetProperty } = slice.actions;

export default slice.reducer;
