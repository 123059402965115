import { RoundButton } from 'components/atoms/Button';
import TextField from 'components/atoms/inputs/textField';
import Text from 'components/atoms/text';
import Spinner from 'components/loading/spinner/Spinner';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { api } from 'services/facility/facility';

import {
    ResultsCard,
    ResultsWrapper,
    RightColumn,
    SeachInputWrapper,
    SpinnerContainer,
    TextButton,
    Title,
} from './styles';

interface SearchServiceIdForm {
    servicingId: string;
}

const IDSearch = () => {
    const { t } = useTranslation('admin');

    const { handleSubmit, control } = useForm<SearchServiceIdForm>({
        defaultValues: { servicingId: '' },
    });

    const [trigger, result] =
        api.endpoints.getProfileByServicingId.useLazyQuery();

    const handleOnSubmit = (data: SearchServiceIdForm) => {
        trigger(data.servicingId);
    };

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <SeachInputWrapper>
                <TextField
                    name='servicingId'
                    control={control}
                    InputProps={{ placeholder: t('root.form.input') }}
                />
                <RoundButton variant='contained' color='primary' type='submit'>
                    {t('root.form.submit')}
                </RoundButton>
            </SeachInputWrapper>
            {!result.isUninitialized && (
                <ResultsWrapper>
                    <Text
                        variant='paragraphSmall'
                        colour='Steel60'
                        weight='bold'
                    >
                        {t('root.result.title')}
                    </Text>
                    {result.isLoading && (
                        <SpinnerContainer>
                            <Spinner />
                        </SpinnerContainer>
                    )}
                    {result.error &&
                        'status' in result.error &&
                        result.error.status === 404 && (
                            <ResultsCard>
                                <div>
                                    <Text>{t('root.notFound.line1')}</Text>
                                    <Text>
                                        {t('root.notFound.line2')}{' '}
                                        <a href='mailto:support@selinafinance.co.uk'>
                                            <Text
                                                as='span'
                                                colour='Advance100'
                                                underline
                                            >
                                                {t('root.notFound.contact')}
                                            </Text>
                                        </a>
                                    </Text>
                                </div>
                            </ResultsCard>
                        )}
                    {result.isSuccess && (
                        <ResultsCard>
                            <div>
                                <Title>
                                    <Text weight='bold'>
                                        {result.data.firstName}{' '}
                                        {result.data.lastName}
                                    </Text>
                                </Title>
                                <div>
                                    <Text variant='paragraphSmall'>
                                        Servicing ID {result.data.servicingId}
                                    </Text>
                                </div>
                            </div>
                            <RightColumn>
                                <TextButton
                                    to={{
                                        pathname: `/`,
                                        search: `applicationId=${result.data.applicationId}`,
                                        state: { impersonation: result.data },
                                    }}
                                >
                                    <Text
                                        variant='paragraphSmall'
                                        colour='Advance100'
                                        weight='bold'
                                    >
                                        {t('root.found.link')}
                                    </Text>
                                </TextButton>
                            </RightColumn>
                        </ResultsCard>
                    )}
                </ResultsWrapper>
            )}
        </form>
    );
};

export default IDSearch;
