import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useGetRepaymentAmountQuery } from 'services/facility/facility';
import { getRepaymentAmount, setRepaymentAmount } from 'state/facility/slice';

import useApplicationId from './useApplicationId';

const useRepaymentAmount = (requestedAmount: number | null) => {
    const dispatch = useDispatch();
    const applicationId = useApplicationId();
    const [repaymentError, setRepaymentError] = useState(false);

    const { isUninitialized, isLoading, error, data } =
        useGetRepaymentAmountQuery(
            { applicationId, requestedAmount },
            {
                skip: !applicationId || !requestedAmount || repaymentError,
            },
        );

    const setRequestedAmountData = useCallback(
        (data) => dispatch(setRepaymentAmount(data)),
        [dispatch],
    );

    useEffect(() => {
        if (error) {
            setRepaymentError(true);
        }
    }, [error]);

    useEffect(() => {
        if (
            !isLoading &&
            !isUninitialized &&
            data &&
            Object.keys(data).length
        ) {
            setRequestedAmountData(data);
        }
    }, [isUninitialized, isLoading, data, setRequestedAmountData]);

    const repaymentAmount = useSelector(getRepaymentAmount);

    return {
        isIdle: isUninitialized,
        isLoading,
        repaymentAmount,
        repaymentError,
    };
};

export default useRepaymentAmount;
