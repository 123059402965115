import { Global, css } from '@emotion/react';

import customProperties from './custom-properties';
import typography from './typography';
import fontFaces from './typography/font-faces';

export const globalStyles = (
    <Global
        styles={css`
            ${fontFaces};
            ${customProperties};

            *,
            *::before,
            *::after {
                box-sizing: border-box;
            }

            svg {
                display: block;
                margin: 0 auto;
            }

            html {
                /**
                 * Font size 10px on default browser settings.
                 * It's used as 62.5% to adapt to changes
                 * in the browser's default font-size.
                 */
                font-size: 62.5%;
            }

            body {
                overflow-x: hidden;
                font-size: 1.6rem;
                font-family: ${typography.fontFamilyNeueSingularDRegular};
                -webkit-font-smoothing: antialiased;
                -moz-font-smoothing: antialiased;
                -o-font-smoothing: antialiased;
            }

            html,
            body {
                height: 100%;
                margin: 0;
                padding: 0;
            }

            body,
            #root {
                display: flex;
                flex: 1 0 auto;
                flex-direction: column;
            }

            #root {
                min-height: 100%;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin: 0;
                font-size: 1.8rem;
            }

            ul,
            li {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            /**
             * Override Firefox's stylesheet for red box
             * shadow on invalid fields
             * @see view-source:resource://gre-resources/forms.css
             */

            :not(output):-moz-ui-invalid {
                box-shadow: none;
            }

            :not(output):-moz-ui-invalid:-moz-focusring {
                box-shadow: none;
            }

            /**
             * Default headings
             */
            h1 {
                ${typography.h1}
            }

            h2 {
                ${typography.h2}
            }

            h3 {
                ${typography.h3}
            }

            h4 {
                ${typography.h4}
            }

            /**
             * Default link
             */

            a {
                ${typography.a}
                cursor: pointer;
            }

            /**
             * Default text
             */

            p,
            dl,
            dd {
                ${typography.p.default}
                margin: 0;
            }

            img {
                /* stylelint-disable-next-line */
                image-rendering: -webkit-optimize-contrast;
            }

            fieldset {
                padding: 0;
                margin: 0;
                border: none;
            }

            /**
             * Accessibility improvements
             */

            [role='tooltip'] {
                display: none;
            }
        `}
    />
);
