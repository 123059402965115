/* eslint-disable no-useless-escape */

export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

/**
 * Convert string or timestamp to date object.
 *
 * @param {String/Number} value - Date as timestamp, ISO string, stringified...
 * @returns {Date} Date.
 */
export const toDate = (value: any): Date => {
    const typeOfValue = typeof value;
    let date = null;

    // Already a Date object
    if (value instanceof Date) {
        return value;
    }

    // Invalid types of values
    if (typeOfValue !== 'string' && typeOfValue !== 'number') {
        return new Date('invalid date');
    }

    if (typeOfValue === 'string') {
        // Convert apis format "/Date(1234567)/" into timestamp
        if (value.match(/\/Date\(.*\)\//i)) {
            value = value.match(/\-?\d+/g)[0];
        }

        // Make sure the stringified timestamps are converted to number
        // before creating the date
        if (!isNaN(value)) {
            value = parseInt(value, 10);
        }
    }

    date = new Date(value);

    return date;
};

export const getMonth = (value: any) => {
    const date = toDate(value);
    return months[date.getMonth()];
};

export const getDayAndMonth = (value: any) => {
    const date = toDate(value);
    const month = months[date.getMonth()];
    const day = date.getDate();

    return `${day} ${month}`;
};

export const getDayMonthAndYear = (value: any) => {
    const date = toDate(value);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
};

export const getDaysBetweenDates = (date2: any, date1 = new Date()) => {
    // @ts-ignore
    return Math.ceil((toDate(date2) - toDate(date1)) / (1000 * 60 * 60 * 24));
};

export const getYearsByMonths = (months: number) => {
    return Math.round(months / 12);
};

export const normalizeValue = (value: number) => {
    return value < 10 ? `0${value}` : value;
};

export const getDateTimeFormat = (value?: any) => {
    const date = toDate(value || new Date());
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${year}-${normalizeValue(month)}-${normalizeValue(
        day,
    )} ${normalizeValue(hours)}:${normalizeValue(minutes)}:${normalizeValue(
        seconds,
    )}`;
};

export const todayDate = (): String => {
    return new Date().toISOString().slice(0, 10);
};

export const isBeforeToday = (value: string) => {
    const inputDate = toDate(value);
    const todayDate = new Date();

    return inputDate.setHours(0, 0, 0, 0) < todayDate.setHours(0, 0, 0, 0);
};
