/* eslint-disable max-len */
import React from 'react';

const Close: React.FC = (props) => (
    <svg viewBox='0 0 12 12' fill='none' {...props}>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.991179 0.99124C1.1539 0.828522 1.41772 0.828522 1.58044 0.99124L5.99985 5.41066L10.4193 0.99124C10.582 0.828522 10.8458 0.828522 11.0085 0.99124C11.1712 1.15396 11.1712 1.41778 11.0085 1.5805L6.58911 5.99991L11.0085 10.4193C11.1712 10.582 11.1712 10.8459 11.0085 11.0086C10.8458 11.1713 10.582 11.1713 10.4193 11.0086L5.99985 6.58917L1.58044 11.0086C1.41772 11.1713 1.1539 11.1713 0.991179 11.0086C0.828461 10.8459 0.828461 10.582 0.991179 10.4193L5.4106 5.99991L0.991179 1.5805C0.828461 1.41778 0.828461 1.15396 0.991179 0.99124Z'
            fill='currentColor'
        />
    </svg>
);

export default Close;
