import { PayloadAction } from '@reduxjs/toolkit';

import { SliceState } from './slice';

export const setPropertyReducer = (
    state: SliceState,
    action: PayloadAction<SliceState>,
) => {
    state.suggestions = action.payload.suggestions;
    state.valuations = action.payload.valuations;
};
