import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { HydrationRenderProvider } from 'helpers/useHydrationRender';
import { ViewportProvider } from 'helpers/useViewport';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import store from 'state/buildStore';
import { theme } from 'theme/material-ui';
import en from 'translations/en';

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: 'en',
    resources: {
        en,
    },
});

type ProvidersProps = {
    children: any;
};

const Providers = ({ children }: ProvidersProps) => {
    return (
        <Provider store={store}>
            <Router>
                <I18nextProvider i18n={i18next}>
                    <HydrationRenderProvider>
                        <IntercomProvider appId='tkhpmxzl'>
                            <StylesProvider injectFirst>
                                <ThemeProvider theme={theme}>
                                    <ViewportProvider>
                                        {children}
                                    </ViewportProvider>
                                </ThemeProvider>
                            </StylesProvider>
                        </IntercomProvider>
                    </HydrationRenderProvider>
                </I18nextProvider>
            </Router>
        </Provider>
    );
};

export default Providers;
