import { FC } from 'react';

import { SignOut } from 'components/icons';
import useAuth from 'helpers/useAuth';
import mixpanel from 'mixpanel-browser';

import { StyledButton } from './Logout.styles';

const Logout: FC = () => {
    const {
        actions: { logout },
    } = useAuth();

    const handleLogout = () => {
        mixpanel.track('Sign out');
        logout();
    };

    return (
        <StyledButton onClick={handleLogout} aria-label='Sign out'>
            <SignOut />
        </StyledButton>
    );
};

export default Logout;
